import React from 'react';
//import './Contact.css';
import FormContact from '../../components/FormContact/FormContact';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import { useTranslation } from 'react-i18next';
//import { BiWorld } from "react-icons/bi";
import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";

import { COLORS } from '../../constants/Constants';

function Contact() {
  const { isDark } = useDarkMode();
  const { t } = useTranslation();

  return (
    <section 
      //className={`py-16 px-4 ${isDark ? 'dark' : ''}`}
      className={`py-4 px-4 ${isDark ? 'dark' : ''}`}
    >
      <div className="max-w-6xl mx-auto">
        <h1 
          //className="text-4xl font-bold mb-8 text-center font-sans text-gray-800 dark:text-white"
          className={`text-4xl md:text-5xl font-bold mb-6 font-sans ${COLORS.gradelux}`}
        >
          {t('contactTitle1')}
        </h1>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/2">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
              <p className="mb-6 font-sans text-gray-600 dark:text-gray-300">
                {t('contactText1')}
              </p>
              <div className="space-y-2 font-sans text-gray-700 dark:text-gray-200">
                <div className="flex items-center px-2">
                  <MdEmail className="mr-2" />
                  <strong className="font-semibold mr-2">{t('contactTxtEmail')}: </strong> noolloon@email.com
                </div>
                <div className="flex items-center px-2">
                  <MdPhone className="mr-2" />
                  <strong className="font-semibold mr-2">{t('contactTxtTelefono')}: </strong> +34&nbsp;679&nbsp;29&nbsp;49&nbsp;31
                </div>
                <div className="flex items-center px-2">
                  <MdLocationOn className="mr-2" />
                  <strong className="font-semibold mr-2">{t('contactTxtDireccion')}: </strong> {t('contactFinBarcelona')},&nbsp;{t('contactFinEspana')}
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
              <FormContact />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;