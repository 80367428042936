import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDarkMode } from '../DarkMode/DarkModeContext';


const LogoComponent = ({ src, alt, width, height }) => (
  <img src={src} alt={alt} width={width} height={height} className="rounded-full" />
);

const Experience = ({ experiences }) => {
  const { t } = useTranslation();
  const { isDark } = useDarkMode();

  console.log('Experiences in Experience component:', experiences); // Para depuración

  if (!experiences || !Array.isArray(experiences)) {
    console.error('experiences is not an array or is undefined:', experiences);
    return <div>No hay experiencias disponibles.</div>;
  }

  return (
    <section id="experience" className={`mb-20 m-3 ${isDark ? 'text-gray-200' : 'text-gray-800'}`}>
      <h2 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl flex items-center mb-8 w-full justify-between">
        <span className="bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">{t('experienceTitle')}</span>
      </h2>
      <div className="space-y-12">
        {experiences.map((exp, index) => (
          <ExperienceItem key={index} {...exp} isDark={isDark} />
        ))}
      </div>
    </section>
  );
}

const ExperienceItem = ({ visible, webVisible, logoSrc, logoAlt, company, position, period, responsibilities, web, isDark }) => {
  if (visible === "false") {
    return null;
  }

  return (
    <div className="relative flex m-3">
      <div className={`absolute top-[3.3rem] left-6 h-[calc(100%)] w-px ${isDark ? 'bg-gray-700' : 'bg-gray-300'}`} aria-hidden="true"></div>
      <div className="flex-shrink-0 w-12 h-12 mt-1">
        <div className={`w-12 h-12 rounded-full border-4 ${isDark ? 'border-gray-800 bg-gray-800' : 'border-gray-200 bg-gray-200'} z-10 overflow-hidden`}>
          <LogoComponent 
            src={logoSrc}
            alt={logoAlt}
            width={48}
            height={48}
          />
        </div>
      </div>
      <div className="ml-6 flex-grow">
        <div className="flex flex-col sm:flex-row justify-between items-start">
          <div className="flex flex-col">
            <div className="flex items-center">
              <h3 className={`text-xl font-bold ${isDark ? 'text-gray-100' : 'text-gray-800'}`}>
                {webVisible === "true" ? (
                  <a target="_blank" rel="noopener noreferrer" className="inline-flex items-center group hover:underline" aria-label={`${company} (opens in a new tab)`} href={`${web}`}>
                    {company}
                    <svg
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-external-link ml-1 h-4 w-4 transition-opacity opacity-0 group-hover:opacity-100" aria-hidden="true">
                      <path d="M15 3h6v6"></path>
                      <path d="M10 14 21 3"></path>
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    </svg>
                  </a>
                ) : (
                  company
                )}
              </h3>
            </div>
            <p className={`text-lg font-semibold ${isDark ? 'text-gray-100' : 'text-gray-700'} mt-1`}>{position}</p>
          </div>
          <div className={`${isDark ? 'text-gray-400' : 'text-gray-500'} w-full sm:w-auto sm:text-right mt-2 sm:mt-0`}>
            <time dateTime={period.split(' - ')[0]}>{period}</time>
          </div>
        </div>
        <ul className="list-disc list-inside mt-4 mb-6 space-y-2" aria-label="Responsabilidades">
          {responsibilities.map((responsibility, index) => (
            <li key={index} className={`leading-relaxed ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
              <span className="vitaminatedText">{responsibility}</span>
            </li>
          ))}
        </ul>
      </div>
  </div>
  );
}

export default Experience;