//import React, { useRef } from 'react';
//import './FormContact.css';
import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
//import ReCAPTCHA from 'react-google-recaptcha-v3';
import { useDarkMode } from '../DarkMode/DarkModeContext';
import { useTranslation } from 'react-i18next';

const FormContact = () => {
    const { isDark } = useDarkMode();
    const { t } = useTranslation();
    const form = useRef();
    const recaptchaRef = useRef();
    const [status, setStatus] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [captchaError, setCaptchaError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [fadeStatus, setFadeStatus] = useState('');
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const [privacyError, setPrivacyError] = useState('');
    //const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);

    const [key, setKey] = useState(0);
    const language = t('langCode').slice(0, 2);
    
    useEffect(() => {
        setKey(prevKey => prevKey + 1);
    }, [isDark]);

    useEffect(() => {
      setKey(prevKey => prevKey + 1);
    }, [language]);

    useEffect(() => {
        let fadeTimer;
        let statusTimer;

        if (status) {
            setFadeStatus('opacity-100');
            fadeTimer = setTimeout(() => {
                setFadeStatus('opacity-0');
            }, 4500);

            statusTimer = setTimeout(() => {
                setStatus('');
            }, 5000);
        }

        return () => {
            clearTimeout(fadeTimer);
            clearTimeout(statusTimer);
        };
    }, [status]);


    /*useEffect(() => {
        if (window.grecaptcha && window.grecaptcha.ready) {
          window.grecaptcha.ready(() => {
            setIsRecaptchaReady(true);
          });
        }
      }, []);*/

      /*useEffect(() => {
        if (isRecaptchaReady && recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }, [isDark, language, isRecaptchaReady]);
*/
/*
useEffect(() => {
    const loadRecaptcha = () => {
      if (window.grecaptcha && window.grecaptcha.ready) {
        window.grecaptcha.ready(() => {
          setIsRecaptchaReady(true);
        });
      } else {
        setTimeout(loadRecaptcha, 100);
      }
    };
    loadRecaptcha();
  }, []);
  */

    const handleCaptcha = (token) => {
        setCaptchaToken(token);
        setCaptchaError('');
        
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const sendEmail = (event) => {
        event.preventDefault();
        setStatus('');
        setCaptchaError('');
        setEmailError('');
        setPrivacyError('');

        const email = form.current.user_email.value;
        if (!validateEmail(email)) {
            //setEmailError('Por favor, introduce un email válido');
            setEmailError(t('formErrorEmail'));
            return;
        }

        if (!captchaToken) {
            //setCaptchaError('Por favor, verifica que no eres un robot');
            setCaptchaError(t('formErrorRobot'));
            return;
        }

        if (!privacyAccepted) {
            setPrivacyError(t('formErrorPrivacy'));
            return;
        }

        setStatus('sending');

        const serviceIdEjs = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateIdEjs = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const publicKeyEjs = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

        emailjs
            .sendForm(
                serviceIdEjs, 
                templateIdEjs, 
                form.current, 
                {
                    publicKey: publicKeyEjs,
                }
            )
            .then(
                () => {
                    setStatus('success');
                    form.current.reset();
                    setCaptchaToken(null);
                    if (recaptchaRef.current) {
                        recaptchaRef.current.reset();
                    }
                },
                (error) => {
                    setStatus('error');
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className={`${isDark ? 'dark' : ''}`}>
            <form ref={form} onSubmit={sendEmail} className="space-y-4">
                <div>
                    <label htmlFor="user_name" className="block mb-1 font-sans font-medium text-gray-700 dark:text-gray-300">{t('contactTxtNombre')}</label>
                    <input type="text" id="user_name" name="user_name" required title="Por favor, ingresa tu nombre" 
                           className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
                </div>
                <div>
                    <label htmlFor="user_email" className="block mb-1 font-sans font-medium text-gray-700 dark:text-gray-300">{t('contactTxtEmail')}</label>
                    <input type="email" id="user_email" name="user_email" required 
                           className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white" />
                    {emailError && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{emailError}</p>}
                </div>
                <div>
                    <label htmlFor="message" className="block mb-1 font-sans font-medium text-gray-700 dark:text-gray-300">{t('contactTxtMensaje')}</label>
                    <textarea id="message" name="message" required 
                              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"></textarea>
                </div>
                {process.env.REACT_APP_DEVELOPER_DEBUGGER === 'true' && (
                    <div>
                        <p>Language: {language}</p>
                        <p>Is Dark: {isDark ? 'Yes' : 'No'}</p>
                    </div>
                )}
                {/* ReCaptcha */}
                {/* <div className={`recaptcha-container ${isDark ? 'dark:dark-recaptcha' : ''}`}>*/}
                <div className="recaptcha-container">
                {/*!isRecaptchaReady && <div>Loading ReCAPTCHA...</div>*/}
                {/*isRecaptchaReady && (*/}
                    <ReCAPTCHA
                        key={key}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleCaptcha}
                        className="mt-4"
                        //hl={t('langDosCode')}
                        //hl="en" // es, en, fr, ca 
                        hl={language}
                        theme={isDark ? 'dark' : 'light'}
                    />
                {/*)}*/}
                </div>
                {captchaError && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{captchaError}</p>}
                
                {/* checkbox */}
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="privacy"
                        name="privacy"
                        checked={privacyAccepted}
                        onChange={(e) => setPrivacyAccepted(e.target.checked)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="privacy" className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
                        {t('formPrivacyLabel')} <a href="/privacy-policy" className="text-blue-600 hover:underline">{t('formPrivacyLink')}</a>
                    </label>
                </div>
                {privacyError && <p className="mt-1 text-sm text-red-600 dark:text-red-400">{privacyError}</p>}

                {/* button submit */}
                <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-600 dark:hover:bg-blue-700 transition duration-300">
                {t('contactTxtEnviar')}
                </button>
            </form>
            {status && (
                <p className={`mt-4 text-sm font-sans transition-opacity duration-500 ${fadeStatus} ${
                    status === 'sending' ? 'text-blue-600 dark:text-blue-400' :
                    status === 'success' ? 'text-green-600 dark:text-green-400' :
                    'text-red-600 dark:text-red-400'
                }`}>
                    {/*status === 'sending' && 'Enviando mensaje...'*/}
                    {/*status === 'success' && 'Mensaje enviado con éxito!'*/}
                    {/*status === 'error' && 'Error al enviar el mensaje. Por favor, inténtalo de nuevo.'*/}
                
                    {status === 'sending' && t('formFinEnviando')}
                    {status === 'success' && t('formFinConExito')}
                    {status === 'error' && t('formFinErrorEnvio')}
                </p>
            )}
        </div>
    );
};

export default FormContact;
