// pages/Portfolio/Portfolio.jsx

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { isDeveloperDebugger } from '../../constants/Config';
import { fetchPortfolioItems } from '../../services/api';
import PortfolioItem from '../../components/PortfolioItem/PortfolioItem';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader';
import LazyImage from '../../components/LazyImage/LazyImage';
//import RandomPhrase from '../../components/RandomPhrase/RandomPhrase';
import { COLORS } from '../../constants/Constants';
import FilterAndSearch from '../../components/FilterAndSearch/FilterAndSearch';

//const PortfolioModal = lazy(() => import('../../components/PortfolioModal/PortfolioModal'));
const PortfolioModal = React.lazy(() => import('../../components/PortfolioModal/PortfolioModal'));

const sortOptions = [
  { value: 'date', label: 'sortByDate' },
  { value: 'alphabetical', label: 'sortAlphabetically' },
  { value: 'id', label: 'sortById' },
  ...(isDeveloperDebugger ? [{ value: 'duration', label: 'sortByDuration' }] : []),
];

const categoriesManual = {
  'web': { en: 'Web', es: 'Web', ca: 'Web' , fr: 'Web'},
  'mobile': { en: 'Mobile', es: 'Móvil', ca: 'Mòbil' , fr: 'Mobile'},
  'design': { en: 'Design', es: 'Diseño', ca: 'Disseny' , fr: 'Design'},
  'backend': { en: 'Backend', es: 'Backend', ca: 'Backend' , fr: 'Backend'}
};

function Portfolio() {
  const name = "Joan Roca Alcarraz";
  //const [portfolioItems, setPortfolioItems] = useState([]);
  const [sortBy, setSortBy] = useState('date');
  const [searchTerm, setSearchTerm] = useState('');
  //const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [loadedItems, setLoadedItems] = useState([]);

  

  const [error, setError] = useState(null);

useEffect(() => {
  let isMounted = true;
  async function loadPortfolioItems() {
    setIsLoading(true);
    setError(null);
    try {
      const items = await fetchPortfolioItems(i18n.language);
      if (isMounted) {
        setLoadedItems(items);
      }
    } catch (error) {
      console.error('Error loading portfolio items:', error);
      if (isMounted) {
        setError(error.message);
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  }
  loadPortfolioItems();

  return () => {
    isMounted = false;
  };
}, [i18n.language]);



  const calculateDuration = (datein, date) => {
    const start = new Date(datein);
    const end = new Date(date);
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  };

  const sortItems = React.useCallback((items) => {
    return [...items].sort((a, b) => {
      if (sortBy === 'date') return new Date(b.date) - new Date(a.date);
      if (sortBy === 'alphabetical') return a.title.localeCompare(b.title);
      if (sortBy === 'id') return a.id - b.id;
      if (sortBy === 'duration') {
        const durationA = calculateDuration(a.datein, a.date);
        const durationB = calculateDuration(b.datein, b.date);
        return durationB - durationA;
      }
      return 0;
    });
  }, [sortBy]);
  
  const filterItems = React.useCallback((items) => {
    return items.filter(item => 
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  const displayedItems = React.useMemo(() => {
    return sortItems(filterItems(loadedItems));
  }, [loadedItems, sortItems, filterItems]);

  /*
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
*/

const [selectedItemIndex, setSelectedItemIndex] = useState(null);
const [direction, setDirection] = useState(null);

const handleItemClick = (item) => {
  const index = displayedItems.findIndex(i => i.id === item.id);
  setSelectedItemIndex(index);
  setDirection(null); // Resetea la dirección al abrir un nuevo modal
};

const handleCloseModal = () => {
  setSelectedItemIndex(null);
  setDirection(null);
};

const handleNextItem = (swipeDirection) => {
  setDirection(swipeDirection || 'left');
  setSelectedItemIndex((prevIndex) => 
    prevIndex < displayedItems.length - 1 ? prevIndex + 1 : 0
  );
};

const handlePreviousItem = (swipeDirection) => {
  setDirection(swipeDirection || 'right');
  setSelectedItemIndex((prevIndex) => 
    prevIndex > 0 ? prevIndex - 1 : displayedItems.length - 1
  );
};

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <section id="tags" className="bg-gray-100 dark:bg-gray-800 py-6">
      <div className="container mx-auto px-4">
        

        <h1 
          //className="text-4xl md:text-5xl font-bold mb-6 font-sans text-gray-800 dark:text-white"
          className={`text-4xl md:text-5xl font-bold mb-6 font-sans ${COLORS.gradelux}`}
        >
          {t('welcome')}
        </h1>
        <p 
          className="text-xl mb-8 font-sans text-gray-600 dark:text-gray-300"
          //className={`text-xl mb-8 font-sans ${COLORS.gradelux}`}
        >
          {/*{t('homeText1')}<RandomPhrase name="Joan Roca" />*/}
          <Trans i18nKey="homeText1" values={{ name }}>
          x<strong>{{ name }}</strong>x 
          </Trans>
        </p>


{/*
        <RandomPhrase name="Joan Roca" />
*/}
        {/*<h1 className="text-4xl font-bold text-center mb-8 text-gray-900 dark:text-white">
          {t('portfolioTitle1')}
        </h1>*/}

        {/* filtros+buscador */}
        {/*
        <div className="mb-4 flex justify-between items-center">
          <select 
            value={sortBy} 
            onChange={(e) => setSortBy(e.target.value)}
            className="p-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
          >
            <option value="date">{t('sortByDate')}</option>
            <option value="alphabetical">{t('sortAlphabetically')}</option>
            <option value="id">{t('sortById')}</option>
            {isDeveloperDebugger && (
              <option value="duration">{t('sortByDuration')}</option>
            )}
          </select>
          <input
            type="text"
            placeholder={t('search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
          />
        </div>
        */}

        <FilterAndSearch 
          sortBy={sortBy}
          onSortChange={setSortBy}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          sortOptions={sortOptions}
          saveFilterInMemory={true} // o false, según prefieras
          //categoriesManual={categoriesManual}
          //items={loadedItems} // Pasamos todos los ítems del portfolio
          //categoriesDisplay="dropdown" // o "inline"
        />


        {/* display items */}

        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {displayedItems.map(item => (
              <PortfolioItem 
                key={item.id} 
                {...item} 
                onClick={() => handleItemClick(item)}
                ImageComponent={LazyImage}
              />
            ))}
          </div>
        )}
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {selectedItemIndex !== null && (
          <PortfolioModal 
            item={displayedItems[selectedItemIndex]}
            onClose={handleCloseModal}
            onNext={handleNextItem}
            onPrevious={handlePreviousItem}
            closeOnOutsideClick={true}
            blurBackground={true}
            showArrows={true}
            direction={direction}
          />
        )}
      </Suspense>
    </section>
  );
}

export default React.memo(Portfolio);