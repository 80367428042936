import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import AboutMe from '../../components/AboutMe/AboutMe';
import Experience from '../../components/AboutMe/Experience';
import Education from '../../components/AboutMe/Education';
import Principal from '../../components/AboutMe/Principal';

const About = () => {
  const { isDark } = useDarkMode();
  const { t } = useTranslation();

  // Obtén las experiencias del archivo de idioma actual
  const experiences = t('experience', { returnObjects: true }) || [];
  //const experiences = t('experience', { returnObjects: true });

  console.log('Experiences in About:', experiences); // Para depuración

  return (
    <section className={`${isDark ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-gray-800">
        <div 
            //className="container mx-auto max-w-[1042px] py-20"
            className="container mx-auto max-w-[1042px]"
        >
            <Principal />
          <AboutMe />
          <Experience experiences={experiences} />
          <Education />
        </div>
      </div>
    </section>
  );
}

export default About;