import React from 'react';
import { IoLogoLinkedin, IoLogoGithub, IoLogoInstagram } from "react-icons/io5";
import { useTranslation, Trans } from 'react-i18next';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import TypewriterIntro from '../../components/TypewriterIntro/TypewriterIntro';
import { COLORS } from '../../constants/Constants';
//import RandomPhrase from '../../components/RandomPhrase/RandomPhrase';

const Principal = () => {
    const { isDark } = useDarkMode();
    const { t } = useTranslation();

    const name = "Joan Roca Alcarraz";

/*
    const introData = {
        title: "Soy Joan Roca Alcarraz",
        phrases: [
          "Desarrollo aplicaciones móviles",
          "Gestión proyectos multiplataforma",
          "Visión creativa y tecnológica",
          "Experiencias digitales únicas",
        ],
    }
*/
const introData = {
    title: t('homeText1', 'Soy Joan Roca Alcarraz'),
    phrases: [
      t('randomPhrase.num1', 'Desarrollo aplicaciones móviles'),
      t('randomPhrase.num2', 'Gestión proyectos multiplataforma'),
      t('randomPhrase.num3', 'Visión creativa y tecnológica'),
      t('randomPhrase.num4', 'Experiencias digitales únicas'),
    ],
  };

    return (
        <section 
            //className={`py-12 ${isDark ? 'dark bg-gray-900' : 'bg-white'}`}
        >
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center">
                    {/*<div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                        <img 
                            src="/images/profile.png" 
                            alt={t('aboutTitle1')} 
                            className="rounded-lg shadow-xl w-full max-w-md mx-auto"
                        />
                    </div>*/}
                    <div 
                        className="w-full lg:w-1/2 mb-8 lg:mb-0 flex justify-center"
                        style={{ transform: 'scaleX(-1)' }}
                    >
                        <img 
                            src="/images/profile.png" 
                            alt={t('aboutTitle1')} 
                            //className="rounded-lg shadow-xl w-full max-w-xs lg:max-w-sm xl:max-w-md"
                            className="rounded-lg shadow-xl w-full filter grayscale"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                    <div className="w-full lg:w-1/2">
                        <h1 
                            //className="font-sans text-4xl md:text-5xl font-bold mb-4 text-gray-900 dark:text-white"
                            //className="font-sans text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent"
                            className={`font-sans text-4xl md:text-5xl font-bold mb-4 ${COLORS.gradelux}`}
                        >
                            <Trans i18nKey="aboutTitle1" values={{ name }}>{{ name }}</Trans>
                        </h1>
                        <TypewriterIntro 
                            title={introData.title}
                            phrases={introData.phrases}
                        />
                        {/*<RandomPhrase/>*/}
                        <p className="font-sans text-lg mb-8 text-gray-600 dark:text-gray-300">
                            {t('aboutText1')}
                        </p>
                        <div className="flex space-x-4 mb-8">
                            <a href="https://www.linkedin.com/in/joan-roca-69aabb81/" target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400">
                                <IoLogoLinkedin />
                            </a>
                            <a href="https://github.com/noolloon" target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400">
                                <IoLogoGithub />
                            </a>
                            <a href="https://github.com/joanqa3" target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400">
                                <IoLogoGithub />
                            </a>
                            <a href="https://www.instagram.com/noolloon5" target="_blank" rel="noopener noreferrer" className="text-2xl text-gray-600 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400">
                                <IoLogoInstagram />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Principal;