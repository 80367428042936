//import './Header.css';
//import SmoothScrollLink from '../common/SmoothScrollLink';
//import { HiOutlineMail } from 'react-icons/hi';
//import { BsFillPersonLinesFill } from 'react-icons/bs';
//import { FaBars, FaTimes, FaGithub, FaLinkedin, FaFacebook, FaLinkedinIn,} from 'react-icons/fa';
// components/Header/Header.jsx
import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes, FaDev } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useDarkMode } from '../DarkMode/DarkModeContext';
import Menu from '../Menu/Menu';
import { isDeveloperDebugger } from '../../constants/Config';

function Header() {
  const [nav, setNav] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleClick = () => setNav(!nav);
  const { isDark } = useDarkMode();

  const navigate = useNavigate();

  const handleNavigation = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setNav(false);
      }
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white/80 dark:bg-gray-800/80 backdrop-blur-md' : 'bg-white dark:bg-gray-800'
    } ${isDark ? 'dark' : ''}`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
      <div className="logo text-2xl font-bold flex items-center">
          <Link 
            to="/" 
            onClick={() => handleNavigation('/')}
            className="font-sans text-gray-800 hover:text-blue-400 dark:text-white dark:hover:text-blue-400 transition duration-300"
          >
            JRAdev
          </Link>
          {isDeveloperDebugger && (
            <>
              <FaDev className="h-6 w-6 text-gray-800 dark:text-white ml-2" aria-hidden="true" />
            </>
          )}
        </div>
        <nav>
          {/* Desktop menu */}
          {!isMobile && <Menu isMobile={false} handleNavigation={handleNavigation} handleClick={handleClick} />}

          {/* Hamburger or Close icon */}
          <motion.div 
            onClick={handleClick} 
            className={`${isMobile ? 'block' : 'hidden'} z-50 relative cursor-pointer`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <AnimatePresence initial={false} mode="wait">
              {!nav ? (
                <motion.div
                  key="bars"
                  initial={{ opacity: 0, rotate: -180 }}
                  animate={{ opacity: 1, rotate: 0 }}
                  exit={{ opacity: 0, rotate: 180 }}
                  transition={{ duration: 0.3 }}
                >
                  <FaBars className="text-2xl" />
                </motion.div>
              ) : (
                <motion.div
                  key="times"
                  initial={{ opacity: 0, rotate: -180 }}
                  animate={{ opacity: 1, rotate: 0 }}
                  exit={{ opacity: 0, rotate: 180 }}
                  transition={{ duration: 0.3 }}
                >
                  <FaTimes className="text-2xl" />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>

          {/* Mobile menu */}
          <AnimatePresence>
            {nav && isMobile && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className='fixed top-0 left-0 w-full h-screen bg-white dark:bg-gray-800 flex flex-col justify-center items-center z-40'
              >
                <Menu isMobile={true} handleClick={handleClick} handleNavigation={handleNavigation} />
              </motion.div>
            )}
          </AnimatePresence>
          
        </nav>
      </div>
    </header>
  );
}

export default Header;