import React from 'react';
import Typewriter from 'typewriter-effect';

const TypewriterIntro = ({ title, phrases }) => {
  return (
    <section 
      //className="bg-gray-100 dark:bg-gray-800"
    >
     {/* 
     <section className="bg-gray-100 dark:bg-gray-800 min-h-screen flex items-center">
     <div className="container mx-auto px-4 py-12 flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0">*/}
          {/*<h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-4">{title}</h2>*/}
          <h2 className="text-1xl md:text-2xl font-bold  mb-4">
            <Typewriter
              options={{
                strings: phrases,
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
                delay: 50,
              }}
            />
          </h2>
        {/* </div>
      </div>*/}
    </section> 
  );
};

export default TypewriterIntro;