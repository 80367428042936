/*
<ScrollToTopButton 
  icon={<FaArrowUp size={20} />}
  scrollThreshold={300}
  position="bottom-right"
  lightBgColor="rgba(255, 255, 255, 0.7)"
  lightIconColor="#000000"
  darkBgColor="rgba(0, 0, 0, 0.7)"
  darkIconColor="#FFFFFF"
  transparency={0.7}
  borderRadius = '50%',
  size = '40px',
  padding = '8px',
  margin = '20px', 
  hoverLightBgColor = 'rgba(255, 255, 255, 0.8)', 
  hoverDarkBgColor = 'rgba(0, 0, 0, 0.8)', 
  bottomThreshold = 50 
/>
*/
// components/common/ScrollToTopButton.jsx
import React, { useState, useEffect, useCallback } from 'react';
import useScrollVisibility from '../../hooks/useScrollVisibility';
import { scrollToTop } from '../../utils/scrollHelpers';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import '../../styles/ScrollToTopButton.css';

const ScrollToTopButton = ({
  icon,
  scrollThreshold = 200,
  position = 'bottom-right',
  lightBgColor = 'rgba(255, 255, 255, 0.5)',
  lightIconColor = '#000000',
  darkBgColor = 'rgba(0, 0, 0, 0.5)',
  darkIconColor = '#FFFFFF',
  transparency = 0.7,
  borderRadius = '30%',
  size = '32px',
  padding = '4px',
  margin = '20px',
  hoverLightBgColor = 'rgba(225, 225, 225, 1)',
  hoverDarkBgColor = 'rgba(0, 0, 0, 1)',
  bottomOffset = 50,
  footerRef
}) => {
  const isVisible = useScrollVisibility(scrollThreshold);
  const { isDark } = useDarkMode();
  const [isHovered, setIsHovered] = useState(false);
  const [buttonPosition, setButtonPosition] = useState(null);

  const updateButtonPosition = useCallback(() => {
    if (!footerRef || !footerRef.current) return;

    const windowHeight = window.innerHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const footerTop = footerRef.current.getBoundingClientRect().top + scrollTop;
    const buttonBottom = windowHeight - bottomOffset;

    if (footerTop < windowHeight) {
      const newPosition = Math.max(footerTop - buttonBottom, 0);
      setButtonPosition(newPosition);
    } else {
      setButtonPosition(null);
    }
  }, [bottomOffset, footerRef]);

  useEffect(() => {
    if (!footerRef || !footerRef.current) return;

    window.addEventListener('scroll', updateButtonPosition);
    window.addEventListener('resize', updateButtonPosition);
    updateButtonPosition();

    return () => {
      window.removeEventListener('scroll', updateButtonPosition);
      window.removeEventListener('resize', updateButtonPosition);
    };
  }, [updateButtonPosition, footerRef]);

  if (!isVisible) {
    return null;
  }

  const getPositionStyle = () => {
    const baseStyle = {
      position: 'fixed',
      [position.includes('right') ? 'right' : 'left']: margin,
    };

    if (buttonPosition !== null) {
      return {
        ...baseStyle,
        bottom: `${bottomOffset}px`,
        transform: `translateY(-${buttonPosition}px)`,
      };
    }

    return {
      ...baseStyle,
      [position.includes('top') ? 'top' : 'bottom']: margin,
    };
  };

  const style = {
    ...getPositionStyle(),
    backgroundColor: isDark 
      ? (isHovered ? hoverDarkBgColor : darkBgColor)
      : (isHovered ? hoverLightBgColor : lightBgColor),
    color: isDark ? darkIconColor : lightIconColor,
    opacity: transparency,
    borderRadius: borderRadius,
    width: size,
    height: size,
    padding: padding
  };

  return (
    <div 
      onClick={scrollToTop} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="scroll-to-top-button"
      style={style}
    >
      <div className="icon-wrapper">
        {icon}
      </div>
    </div>
  );
};

export default ScrollToTopButton;