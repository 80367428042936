import React from 'react';
import ReactPlayer from 'react-player';
import './ComputerVideoPlayer.css';

const ComputerVideoPlayer = ({ videoUrl, isLaptop, backgroundImage }) => {
  return (
    <div className="computer-container">
      <div className={`computer-frame ${isLaptop ? 'laptop' : 'desktop'}`} style={{backgroundImage: `url(${backgroundImage})`}}>
        <div className="screen">
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            playing
            loop
            controls={false}
            stopOnUnmount={false}
            muted
            playsinline
          />
        </div>
      </div>
    </div>
  );
};

export default ComputerVideoPlayer;