// hooks/ScrollToTop.jsx
import React from 'react';
//import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {

    const { pathname } = useLocation();
    
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return null;
  }