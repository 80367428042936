//App.jsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import About from './pages/About/About';
import About3 from './pages/About/About3';
import Portfolio from './pages/Portfolio/Portfolio';
import Skills from './pages/Skills/Skills';
import Contact from './pages/Contact/Contact';
import Policy from './pages/Legal/Policy';
import Terms from './pages/Legal/Terms';
import { DarkModeProvider } from './components/DarkMode/DarkModeContext';
import { LanguageProvider } from './components/LanguageSelector/LanguageContext';
import MobilePrompt from './components/MobilePrompt/MobilePrompt';
import CookieConsent from './components/Cookie/CookieConsent';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import Prova1 from './pages/Proves/Prova1';
import Prova2 from './pages/Proves/Prova2';
import Comparar from './pages/About/Comparar';
import { isDeveloperDebugger } from './constants/Config';
import LocalStorageViewer from './utils/LocalStorageViewer';
import './App.css';
import { ScrollToTop } from './hooks/ScrollToTop';

const config = {
  enabled: false,
  position: 'medDer', // (izqTop, medTop, derTop, medIzq, cenMed, medDer, izqBot, medBot, derBot)
  padding: { x: 16, y: 16 },
  interactionRequired: true, // Si es verdadero, el usuario debe cerrar el mensaje
  reminderDays: 1,
  deviceType: 'both', // (mobile, web, both)
  minWidth: 770 // pixels al redimensionar la ventana y desaparecer
};


function AppContent() {
  return (
    <div className="App bg-white dark:bg-gray-900 text-black dark:text-white min-h-screen flex flex-col">
      <ScrollToTop />
      <Header />
      <main className="flex-grow pt-16">
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/about3" element={<About3 />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/legal/terms" element={<Terms />} />
          <Route path="/legal/policy" element={<Policy />} />
          <Route path="/prova1/" element={<Prova1 />} />
          <Route path="/prova2" element={<Prova2 />} />
          <Route path="/comparar" element={<Comparar />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <Footer />
      {isDeveloperDebugger && (
        <>
          <CookieConsent />
          <LocalStorageViewer />
        </>
      )}
    </div>
  );
}

function App() {
  return (
    <DarkModeProvider>
      <LanguageProvider>
        <MobilePrompt config={config} />
        <Router>
          <AppContent />
        </Router>
      </LanguageProvider>
    </DarkModeProvider>
  );
}

export default App;

