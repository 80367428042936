// LocalStorageViewer.jsx
// v1.0.0 2024-10-09 JRAdev 
import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { FaMoon, FaSun, FaMinusCircle, FaPlusCircle, FaCopy, FaTrash, FaEdit, FaDownload, FaSync, FaFilter, FaUndo, FaEyeSlash, FaEye } from "react-icons/fa";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { MdCopyAll } from "react-icons/md";
import { BsArrowsMove } from "react-icons/bs";
import { 
  PiArrowSquareUpLeftFill, 
  PiArrowSquareUpFill, 
  PiArrowSquareUpRightFill,
  PiArrowSquareLeftFill,
  PiArrowSquareRightFill,
  PiArrowSquareDownLeftFill, 
  PiArrowSquareDownFill,
  PiArrowSquareDownRightFill
} from "react-icons/pi";

// Mis Componentes
const lightTheme = {
  background: '#f5f5f5',
  text: '#333',
  border: '#ddd',
  itemBackground: '#fff',
  keyColor: '#0066cc',
  valueColor: '#666',
};

const darkTheme = {
  background: '#333',
  text: '#f5f5f5',
  border: '#555',
  itemBackground: '#444',
  keyColor: '#66b3ff',
  valueColor: '#ccc',
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin: 0;
`;
/*
const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.text};
  font-size: 18px;
  margin-left: 10px;
`;
*/
const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.theme.text};
  font-size: 18px;
  margin-left: 10px;
  transition: color 0.3s ease, transform 0.2s ease;

  &:hover {
    color: ${props => props.theme.keyColor};
    transform: scale(1.1);
  }
`;

const PositionSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-bottom: 10px;
`;

const PositionButton = styled.button`
  background: none;
  border: 1px solid ${props => props.theme.border};
  color: ${props => props.theme.text};
  padding: 5px;
  cursor: pointer;
  &.active {
    background-color: ${props => props.theme.keyColor};
    color: ${props => props.theme.background};
  }
`;

const SizeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SizeButton = styled.button`
  background: none;
  border: 1px solid ${props => props.theme.border};
  border-radius: 6px;
  transition: 'background-color 0.3s';
  color: ${props => props.theme.text};
  padding: 5px 10px;
  cursor: pointer;
  &.active {
    background-color: ${props => props.theme.keyColor};
    color: ${props => props.theme.background};
  }
`;


const ViewerContainer = styled.div`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  ${props => {
    switch(props.position) {
      case 'top-left': return 'top: 10px; left: 10px;';
      case 'top-center': return 'top: 10px; left: 50%; transform: translateX(-50%);';
      case 'top-right': return 'top: 10px; right: 10px;';
      case 'middle-left': return 'top: 50%; left: 10px; transform: translateY(-50%);';
      case 'middle-center': return 'top: 50%; left: 50%; transform: translate(-50%, -50%);';
      case 'middle-right': return 'top: 50%; right: 10px; transform: translateY(-50%);';
      case 'bottom-left': return 'bottom: 10px; left: 10px;';
      case 'bottom-center': return 'bottom: 10px; left: 50%; transform: translateX(-50%);';
      case 'bottom-right': return 'bottom: 10px; right: 10px;';
      default: return '';
    }
  }}
  width: ${props => {
    switch(props.size) {
      case 'small': return '300px';
      case 'medium': return '500px';
      case 'large': return '800px';
      default: return '500px';
    }
  }};
  opacity: ${props => 1 - props.transparency / 100};
  max-width: 95vw; // Evita que el contenedor sea más ancho que la ventana
  box-sizing: border-box; // Incluye padding en el ancho total
  overflow-x: hidden; // Oculta el scroll horizontal
`;


const ListContainer = styled.div`
  max-height: 300px; // Ajusta este valor según tus necesidades
  //overflow-y: auto;
  margin-top: 10px;
  padding-right: 10px; // Para evitar que el scroll oculte contenido
`;

const ListItem = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.borderColor};

  &:last-child {
    border-bottom: none;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  //justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  margin-bottom: 5px;
`;

const KeyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  word-break: break-all;
`;

const Key = styled.span`
  font-weight: bold;
  //margin-right: 10px;
  word-break: break-all; // break
`;

const ValueContainer = styled.div`
  background-color: ${props => props.theme.isDarkMode 
    ? 'rgba(255, 255, 255, 0.1)'  // Gris claro transparente para modo oscuro
    : 'rgba(0, 0, 0, 0.1)'        // Gris oscuro transparente para modo claro
  };
  padding: 5px;
  border-radius: 4px;
  word-break: break-all;
`;

const Value = styled.span`
  font-family: monospace;
`;

/*
const IconGroup = styled.div`
  display: flex;
  gap: 5px;
`;
*/
const IconGroup = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: flex-end;
  //margin-bottom: 5px;
`;

const StyledButton = styled.button`
background-color: ${props => props.baseColor || 'green'};
color: white;
padding: 10px 20px;
border-radius: 6px;
border: none;
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
transition: background-color 0.3s;

&:hover {
  background-color: ${props => props.hoverColor || 'darkgreen'};
}
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const Icon = styled.span`
  font-size: 1.2em;
  margin: 0 10px;
  cursor: pointer;
  color: ${props => props.theme.text};
  transition: color 0.3s ease, transform 0.2s ease;

  &:hover {
    color: ${props => props.theme.keyColor};
    transform: scale(1.1);
  }

`;

const TransparencySlider = styled.input`
  flex-grow: 1;
  -webkit-appearance: none;
  width: 100%;
  height: 3px; 
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px; // Bola más pequeña
    height: 15px; // Bola más pequeña
    border-radius: 50%;
    background: #66B3FF;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px; // Bola más pequeña
    height: 15px; // Bola más pequeña
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
`;

const TransparencyControl = ({ transparency, updatePreference }) => {
  const handleChange = (e) => {
    const invertedValue = 80 - parseInt(e.target.value);
    updatePreference('transparency', invertedValue);
  };

  const setMinTransparency = () => {
    updatePreference('transparency', 80);
  };

  const setMaxTransparency = () => {
    updatePreference('transparency', 0);
  };

  return (
    <SliderContainer>
      <Icon onClick={setMinTransparency}><FaEyeSlash /></Icon>
      <TransparencySlider
        type="range"
        min="0"
        max="80"
        value={80 - transparency}
        onChange={handleChange}
      />
      <Icon onClick={setMaxTransparency}><FaEye /></Icon>
    </SliderContainer>
  );
};

const PREFERENCES_KEY = "localStorageViewerPreferences";
const DEFAULT_PREFERENCES = {
  isDarkMode: true,
  isMinimized: false,
  showPreferences: false,
  position: "middle-right",
  size: "medium",
  transparency: 0
};

const positionIcons = {
  'top-left': PiArrowSquareUpLeftFill,
  'top-center': PiArrowSquareUpFill,
  'top-right': PiArrowSquareUpRightFill,
  'middle-left': PiArrowSquareLeftFill,
  'middle-center': BsArrowsMove,
  'middle-right': PiArrowSquareRightFill,
  'bottom-left': PiArrowSquareDownLeftFill,
  'bottom-center': PiArrowSquareDownFill,
  'bottom-right': PiArrowSquareDownRightFill
};

// IconButtonWithTooltip
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled.span`
  visibility: hidden;
  //background-color: rgba(0, 0, 0, 0.8);
  background-color: ${props => props.theme.isDarkMode 
    ? 'rgba(255, 255, 255, 0.1)'  // Gris claro transparente para modo oscuro
    : 'rgba(0, 0, 0, 0.1)'        // Gris oscuro transparente para modo claro
  };

  //color: #fff;
  color: ${props => props.theme.isDarkMode 
    ? '#fff'
    : 'rgb(102, 179, 255)'
  };
  text-align: center;
  font-size: 12px;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const IconButtonWithTooltip = ({ onClick, icon, tooltip }) => (
  <TooltipContainer>
    <IconButton onClick={onClick}>
      {icon}
    </IconButton>
    <Tooltip>{tooltip}</Tooltip>
  </TooltipContainer>
);

const LocalStorageViewer = () => {
  const [preferences, setPreferences] = useState(() => {
  const savedPreferences = localStorage.getItem(PREFERENCES_KEY);
  return savedPreferences ? JSON.parse(savedPreferences) : DEFAULT_PREFERENCES;
  });

  const [storageItems, setStorageItems] = useState({});

  const { isDarkMode, isMinimized, showPreferences, position, size, transparency } = preferences;

  const loadStorageItems = () => {
  const items = { ...localStorage };
  if (!showPreferences) {
    delete items[PREFERENCES_KEY];
  }
  setStorageItems(items);
  };

  useEffect(() => {
  loadStorageItems();
  }, [showPreferences]);

  const updatePreference = (key, value) => {
  setPreferences(prev => {
    const newPreferences = { ...prev, [key]: value };
    localStorage.setItem(PREFERENCES_KEY, JSON.stringify(newPreferences));
    return newPreferences;
  });
  };

  const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    alert('Copiado al portapapeles');
  });
  };

  const clearAllStorage = () => {
  if (window.confirm('¿Estás seguro de que quieres borrar todo el localStorage?')) {
    const includePreferences = window.confirm('¿Quieres incluir las preferencias de la herramienta en el borrado?');
    if (includePreferences) {
    localStorage.clear();
    } else {
    const preferences = localStorage.getItem(PREFERENCES_KEY);
    localStorage.clear();
    if (preferences) {
      localStorage.setItem(PREFERENCES_KEY, preferences);
    }
    }
    loadStorageItems();
  }
  };

  const deleteItem = (key) => {
  if (window.confirm(`¿Estás seguro de que quieres borrar el item "${key}"?`)) {
    localStorage.removeItem(key);
    loadStorageItems();
  }
  };

  const editItem = (key, currentValue) => {
  const newValue = prompt(`Editar valor para "${key}"`, currentValue);
  if (newValue !== null) {
    localStorage.setItem(key, newValue);
    loadStorageItems();
  }
  };

  const exportData = () => {
  const includePreferences = window.confirm('¿Quieres incluir las preferencias de la herramienta en la exportación?');
  let dataToExport = { ...storageItems };
  if (!includePreferences) {
    delete dataToExport[PREFERENCES_KEY];
  }
  const data = JSON.stringify(dataToExport, null, 2);
  const blob = new Blob([data], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'localStorage_export.txt';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  };

  const copyAllToClipboard = () => {
    const includePreferences = window.confirm('¿Quieres incluir las preferencias de la herramienta en la copia?');
    let dataToCopy = { ...storageItems };
    if (!includePreferences) {
      delete dataToCopy[PREFERENCES_KEY];
    }
    const data = JSON.stringify(dataToCopy, null, 2);
    copyToClipboard(data);
  };

  const resetPreferences = () => {
    setPreferences(DEFAULT_PREFERENCES);
    localStorage.setItem(PREFERENCES_KEY, JSON.stringify(DEFAULT_PREFERENCES));
  };

  const toggleShowPreferences = () => {
    updatePreference('showPreferences', !showPreferences);
  };

  return (
  <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
    <ViewerContainer position={position} size={size} transparency={transparency}>
    <Header>
      <Title>LocalStorage Viewer</Title>
      <IconGroup>
      <IconButton onClick={() => updatePreference('isDarkMode', !isDarkMode)}>
        {isDarkMode ? <FaSun /> : <FaMoon />}
      </IconButton>
      <IconButton onClick={() => updatePreference('isMinimized', !isMinimized)}>
        {isMinimized ? <FaPlusCircle /> : <FaMinusCircle />}
      </IconButton>
      <IconButton onClick={toggleShowPreferences}>
        {showPreferences ? <FaFilter/> : <FaFilterCircleXmark />}
      </IconButton>
      <IconButton onClick={resetPreferences}><FaUndo /></IconButton>
      </IconGroup>
    </Header>
    {!isMinimized && (
      <>
      <PositionSelector>
        {Object.entries(positionIcons).map(([pos, Icon]) => (
        <PositionButton
          key={pos}
          className={position === pos ? "active" : ""}
          onClick={() => updatePreference("position", pos)}
        >
          <Icon />
        </PositionButton>
        ))}
      </PositionSelector>
      <SizeSelector>
        {['small', 'medium', 'large'].map(s => (
        <SizeButton
          key={s}
          className={size === s ? 'active' : ''}
          onClick={() => updatePreference('size', s)}
        >
          {s.charAt(0).toUpperCase() + s.slice(1)}
        </SizeButton>
        ))}
        <StyledButton 
        onClick={loadStorageItems}
        baseColor="green"
        hoverColor="darkgreen"
        label="Refresh"
        >
        <FaSync style={{marginBottom: '0px'}} />
        </StyledButton>
      </SizeSelector>
      <TransparencyControl 
        transparency={transparency} 
        updatePreference={updatePreference}
      />
      <IconGroup>
        <IconButton onClick={clearAllStorage}><FaTrash /></IconButton>
        <IconButton onClick={exportData}><FaDownload /></IconButton>
        <IconButton onClick={copyAllToClipboard}><MdCopyAll /></IconButton>
      </IconGroup>
      <ListContainer style={{margin:'0px'}}>
        {Object.entries(storageItems).length > 0 ? (
        Object.entries(storageItems).map(([key, value]) => (
          <ListItem key={key}>
          <ItemHeader>
            <KeyContainer>
              <Key>{key}</Key>
            </KeyContainer>
            <IconGroup>
              <IconButtonWithTooltip onClick={() => copyToClipboard(key)} icon={<FaCopy />} tooltip="Copy Key" />
              <IconButtonWithTooltip onClick={() => copyToClipboard(value)} icon={<FaCopy />} tooltip="Copy Value" />
              <IconButtonWithTooltip onClick={() => editItem(key, value)} icon={<FaEdit />} tooltip="Edit" />
              <IconButtonWithTooltip onClick={() => deleteItem(key)} icon={<FaTrash />} tooltip="Delete" />
            </IconGroup>
          </ItemHeader>
          <ValueContainer>
            <Value>{value}</Value>
          </ValueContainer>
          </ListItem>
        ))
        ) : (
        <ListItem style={{textAlign:"center", color:"#66B3FF"}}>
          There are no items in the localStorage
        </ListItem>
        )}
      </ListContainer>
      </>
    )}
    </ViewerContainer>
  </ThemeProvider>
  );
};

export default LocalStorageViewer;
