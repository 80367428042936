// components/Menu/Menu.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DarkModeToggle from '../DarkMode/DarkModeToggle';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { GrLanguage } from "react-icons/gr";

const Menu = ({ isMobile, handleClick, handleNavigation }) => {
  const { t } = useTranslation();

  // { key: 'home', path: '/' },
  const menuItems = [
    { key: 'portfolio', path: '/portfolio' },
    { key: 'about', path: '/about' },
    { key: 'skills', path: '/skills' },
    { key: 'contact', path: '/contact' }
  ];

  return (
    <ul className={isMobile ? 'flex flex-col items-center space-y-6' : 'hidden md:flex space-x-4 list-none'}>
      {menuItems.map((item) => (
        <li key={item.key} className={isMobile ? 'text-4xl' : ''}>
          <NavLink 
            to={item.path}
            onClick={() => {
              if (isMobile) handleClick();
              if (handleNavigation) handleNavigation(item.path);
            }}
            className="font-sans font-bold hover:text-blue-600 dark:hover:text-blue-400 transition duration-300"
          >
            {t(`menu.${item.key}`)}
          </NavLink>
        </li>
      ))}
      <li><DarkModeToggle /></li>
      <li>
        <LanguageSelector
          autoCloseTime={3000}
          tipo={isMobile ? "text" : "TWdropdown"}
          icon={isMobile ? null : <GrLanguage />}
          showFlags={!isMobile}
          showText={isMobile}
          customClass={isMobile ? "footer-language-selector" : "header-language-selector"}
          languages={['es', 'en', 'cat', 'fr']}
        />
      </li>
    </ul>
  );
};

export default Menu;