import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDarkMode } from '../DarkMode/DarkModeContext';

//import { User, Mail, Phone, Globe, Award, Code, Briefcase, GraduationCap, FileText } from 'lucide-react'
import { Award, Code } from 'lucide-react'

/*
const InfoItem = ({ icon: Icon, title, content }) => (
  <div className="flex items-start mb-4">
    <Icon className="w-6 h-6 mr-2 text-blue-600 flex-shrink-0" />
    <div>
      <h3 className="font-semibold">{title}</h3>
      <div className="text-gray-600">{content}</div>
    </div>
  </div>
)
  */

const Education = () => {
  const { t } = useTranslation();
  const { isDark } = useDarkMode();

  const InfoItem = ({ icon: Icon, title, content, link, target, isDark }) => (
    <div className="flex items-start mb-4">
      <Icon className="w-6 h-6 mr-2 text-blue-600 flex-shrink-0" />
      <div>
        <h3 className="font-semibold">{title}</h3>
        <div className={`${isDark ? 'text-gray-200' : 'text-gray-600'}`}>
          {content}
        </div>
        <div className={`${isDark ? 'text-gray-200' : 'text-gray-600'}`}>
          {link && (
              <a
                href={link}
                target={target}
                className=" text-blue-600 hover:underline"
                aria-label={`Ver más sobre ${title}`}
              >
                {t('ver')}
              </a>
            )}
          </div>
      </div>
    </div>
  )
  
  // Obtén los datos de educación del JSON usando la función t
  const educationPlaces = t('education.places', { returnObjects: true });

  return (
    <section id="education" className={`py-8 lg:py-18 m-3 ${isDark ? 'text-gray-200' : 'text-gray-800'}`}>
      <h2 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl flex items-center mb-8 w-full justify-between">
        <span className="bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">
          {t('education.title')}
        </span>
      </h2>
      <div className="space-y-8 m-3">
        {educationPlaces.map((edu, index) => (
          <EducationItem 
            key={index} 
            {...edu} 
            isDark={isDark}
            //logoSrc={`/images/about/pic-${edu.name.toLowerCase().replace(' ', '-')}.${edu.name === 'IDEP' ? 'png' : 'webp'}`}
            //logoSrc={`${edu.name}`}
          />
        ))}
      </div>
      <div className="relative m-8">
        <InfoItem className="m-4"
          icon={Award} 
          title={t('certificaciones')}
          content={t('certMeta')}
          link="https://www.coursera.org/account/accomplishments/professional-cert/GYBM65XAL14H?utm_source=ln&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=prof"
          target="_blank"
          isDark={isDark}
        />
        <InfoItem
          icon={Code} 
          title={t('habilidadesTecnicas')}
          content="Swift, ReactNative, Flutter, MySQL, PostgreSQL, CoreData, PHP, Woocommerce, Agile, Scrum Master, JIRA, PowerBI, ..." 
          link="/skills"
          isDark={isDark}
        />
      </div>
    </section>
  );
}

const EducationItem = ({ logoSrc, name, degree, period, web, isDark }) => (
  <article className="flex flex-row items-start gap-4 sm:gap-8">
    <div className="w-16 h-16 relative flex-shrink-0">
      <img
        alt={`${name} logo`}
        loading="lazy"
        decoding="async"
        data-nimg="fill"
        className="rounded-full object-contain"
        style={{ position: 'absolute', height: '100%', width: '100%', left: 0, top: 0, right: 0, bottom: 0, color: 'transparent' }}
        sizes="(max-width: 640px) 64px, 64px"
        src={logoSrc}
      />
    </div>
    <div className="flex flex-col flex-grow">
      <header className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2">
        <h3>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={`text-xl font-bold hover:underline inline-flex items-center group ${isDark ? 'text-gray-100' : 'text-gray-800'}`}
            aria-label={`${name} (opens in a new tab)`}
            href={web}
          >
            {name}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-external-link ml-1 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity"
              aria-hidden="true"
            >
              <path d="M15 3h6v6"></path>
              <path d="M10 14 21 3"></path>
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
            </svg>
          </a>
        </h3>
        <time
          className={`${isDark ? 'text-gray-400' : 'text-gray-600'} w-full sm:w-auto sm:text-right mt-1 sm:mt-0`}
          dateTime={period.split(' - ')[0]}
        >
          {period}
        </time>
      </header>
      <p className={`text-base sm:text-lg ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
        {degree}
      </p>
    </div>
  </article>
);

export default Education;