/*
Uso :
        <FilterAndSearch 
          sortBy={sortBy}
          onSortChange={setSortBy}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          sortOptions={sortOptions}
        />

Y en sortOptions :
    const sortOptions = [
        { value: 'date', label: 'sortByDate' },
        { value: 'alphabetical', label: 'sortAlphabetically' },
        { value: 'id', label: 'sortById' },
        ...(isDeveloperDebugger ? [{ value: 'duration', label: 'sortByDuration' }] : []),
    ];
*/
// components/FilterAndSearch/FilterAndSearch.js
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FunnelIcon, MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/solid';

const FilterAndSearch = ({ 
  sortBy, 
  onSortChange, 
  searchTerm, 
  onSearchChange, 
  sortOptions, 
  saveFilterInMemory = false,
  categoriesManual = {},
  items = []
}) => {
  const { t, i18n } = useTranslation();
  const [localSortBy, setLocalSortBy] = useState(sortBy);

  useEffect(() => {
    if (saveFilterInMemory) {
      const savedSort = localStorage.getItem('portfolioSort');
      if (savedSort) {
        setLocalSortBy(savedSort);
        onSortChange(savedSort);
      }
    }
  }, [saveFilterInMemory, onSortChange]);

  const handleClearSearch = () => {
    onSearchChange('');
  };

  const handleSortChange = (value) => {
    setLocalSortBy(value);
    onSortChange(value);
    if (saveFilterInMemory) {
      localStorage.setItem('portfolioSort', value);
    }
  };

  const handleCategoryClick = (category) => {
    onSearchChange(category);
  };

  // Combinar categorías manuales y categorías de los items
  const categories = useMemo(() => {
    const manualCategoryKeys = Object.keys(categoriesManual);
    const itemCategories = items.flatMap(item => item.categories || []);
    const allCategories = [...new Set([...manualCategoryKeys, ...itemCategories])];
    return allCategories.sort((a, b) => {
      const aTranslated = categoriesManual[a]?.[i18n.language] || t(`categories.${a}`);
      const bTranslated = categoriesManual[b]?.[i18n.language] || t(`categories.${b}`);
      return aTranslated.localeCompare(bTranslated);
    });
  }, [items, categoriesManual, t, i18n.language]);

  return (
    <div className="mb-4 space-y-4">
      <div className="flex justify-between items-center space-x-4">
        <div className="relative flex-1">
          <FunnelIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <select 
            value={localSortBy} 
            onChange={(e) => handleSortChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white appearance-none"
          >
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {t(option.label)}
              </option>
            ))}
          </select>
        </div>
        <div className="relative flex-1">
          <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder={t('search')}
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            className="w-full pl-10 pr-10 py-2 border rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
          />
          {searchTerm && (
            <XCircleIcon
              className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 cursor-pointer"
              onClick={handleClearSearch}
            />
          )}
        </div>
      </div>
      {categories.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              {categoriesManual[category]?.[i18n.language] || t(`categories.${category}`)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterAndSearch;