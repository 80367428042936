//components/Cookie/CookieConsent.jsx
import React, { useState, useEffect } from 'react';
import styles from './CookieConsent.module.css';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true,
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      try {
        const parsedConsent = JSON.parse(consent);
        setCookiePreferences(parsedConsent);
      } catch (error) {
        console.error('Error parsing cookie consent:', error);
        setCookiePreferences({
          essential: true,
          analytics: false,
          marketing: false
        });
      }
    } else {
      setShowConsent(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const allAccepted = {
      essential: true,
      analytics: true,
      marketing: true
    };
    setCookiePreferences(allAccepted);
    localStorage.setItem('cookieConsent', JSON.stringify(allAccepted));
    setShowConsent(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(cookiePreferences));
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className={styles.cookieDialog}>
      <div className={styles.cookieCard}>
        <div className={styles.first}>
          <span>Preferencias de cookies</span>
          <button className={styles.closeIcon} onClick={() => setShowConsent(false)}>×</button>
        </div>
        <p className={styles.tips}>Utilizamos cookies para mejorar su experiencia en nuestro sitio web.</p>
        <div className={styles.checkboxGroup}>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={cookiePreferences.essential}
              disabled
            /> Esenciales
          </label>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={cookiePreferences.analytics}
              onChange={(e) => setCookiePreferences({...cookiePreferences, analytics: e.target.checked})}
            /> Analíticas
          </label>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={cookiePreferences.marketing}
              onChange={(e) => setCookiePreferences({...cookiePreferences, marketing: e.target.checked})}
            /> Marketing
          </label>
        </div>
        <div className={styles.twoBtns}>
          <button className={styles.rejectAll} onClick={handleSavePreferences}>Guardar preferencias</button>
          <button className={styles.acceptAll} onClick={handleAcceptAll}>Aceptar todas</button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;