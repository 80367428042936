import React, { useState, useEffect } from 'react';

const Version = () => {
  const fullVersion = process.env.REACT_APP_VERSION || '-.-.-';
  const [version, setVersion] = useState(fullVersion);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Ajusta este valor según tus necesidades
        setVersion(fullVersion.split(' ')[0]); // Solo muestra la parte "1.0.1"
      } else {
        setVersion(fullVersion); // Muestra la versión completa
      }
    };

    handleResize(); // Llamada inicial
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [fullVersion]);

  return (
    <div className="text-sm">
      v{version}
    </div>
  );
};

export default Version;