import React from 'react';
import { IoMoon, IoSunny } from "react-icons/io5";
import { useDarkMode } from './DarkModeContext';

function DarkModeToggle() {
  const { isDark, toggleDarkMode } = useDarkMode();

  return (
    <button onClick={toggleDarkMode} className="p-2 text-gray-800 dark:text-white">
      {isDark ? <IoSunny /> : <IoMoon />}
    </button>
  );
}

export default DarkModeToggle;