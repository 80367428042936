//MobilePrompt.jsx - Componente que muestra un mensaje en la parte inferior derecha de la pantalla si es la primera vez que el usuario entra en la web y está en un móvil. El mensaje le invita a escanear un código QR para instalar la aplicación en su dispositivo móvil.
// (izqTop, medTop, detTop, medIzq, cenMed, medDer, izqBot, medBot, derBot)
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Hook personalizado para detectar el ancho de la ventana
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};

const MobilePrompt = ({ config }) => {
  const { t } = useTranslation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const windowWidth = useWindowWidth();

  const minimizePrompt = useCallback(() => {
    setIsMinimized(true);
  }, []);

  useEffect(() => {
    if (!config.enabled) return;

    const lastVisit = localStorage.getItem('lastVisit');
    const now = new Date().getTime();
    const isTimeToShow = !lastVisit || (now - parseInt(lastVisit)) / (1000 * 60 * 60 * 24) >= config.reminderDays;
    
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const shouldShow = (config.deviceType === 'both') || 
                       (config.deviceType === 'mobile' && isMobile) || 
                       (config.deviceType === 'web' && !isMobile);

    if (isTimeToShow && shouldShow) {
      setShowPrompt(true);
      if (!config.interactionRequired) {
        localStorage.setItem('lastVisit', now.toString());
      }
      
      // Configurar temporizador para minimizar después de 10 segundos sin interacción
      const timer = setTimeout(minimizePrompt, 10000);
      return () => clearTimeout(timer);
    }
  }, [config, minimizePrompt]);

  const closePrompt = () => {
    setShowPrompt(false);
    if (config.interactionRequired) {
      localStorage.setItem('lastVisit', new Date().getTime().toString());
    }
  };

  const getPositionClasses = () => {
    const positions = {
      izqTop: 'top-0 left-0',
      medTop: 'top-0 left-1/2 -translate-x-1/2',
      derTop: 'top-0 right-0',
      medIzq: 'top-1/2 left-0 -translate-y-1/2',
      cenMed: 'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
      medDer: 'top-1/2 right-0 -translate-y-1/2',
      izqBot: 'bottom-0 left-0',
      medBot: 'bottom-0 left-1/2 -translate-x-1/2',
      derBot: 'bottom-0 right-0'
    };
    return positions[config.position] || positions.derBot;
  };

  if (!showPrompt || windowWidth < config.minWidth) return null;

  const expandPrompt = () => {
    setIsMinimized(false);
  };

  if (isMinimized) {
    return (
      <div 
        className={`fixed z-30 w-20 h-20 bg-blue-500 rounded-full cursor-pointer ${getPositionClasses()}`}
        style={{margin: `${config.padding.y}px ${config.padding.x}px`}}
        onClick={expandPrompt}
      />
    );
  }

  return (
    <div 
      className={`fixed z-30 w-[350px] rounded-lg border p-4 shadow-sm bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-600 ${getPositionClasses()} hidden sm:block`} 
      style={{margin: `${config.padding.y}px ${config.padding.x}px`}}
    >
      {/* El contenido del prompt permanece igual */}
      <div className="relative">
        <button
          type="button"
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 focus:outline-none"
          onClick={closePrompt}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-6 h-6 fill-blue-500"
          >
            <path d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="text-center pb-4 fill-blue-500">
          <div className="text-lg font-medium text-gray-800 dark:text-gray-200">
            <svg
              aria-hidden="true"
              className="w-8 h-8 fill-blue-500 text-gray-600 dark:text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M64 448c0 8.8 7.2 16 16 16H304c8.8 0 16-7.2 16-16V368H64v80zm0-128H320V64c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16V320zM16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM192 392a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
              />
            </svg>
          </div>
        </div>
        <div className="text-center text-lg font-medium text-gray-800 dark:text-gray-200">
          {t('mobilePrompt1')}
        </div>
        <div className="text-center text-base text-gray-600 dark:text-gray-400">
          {t('mobilePrompt2')}
        </div>
        <div className="mt-4 flex items-center justify-center">
          <div className="rounded-md p-2 bg-gray-200 dark:bg-gray-700 border border-gray-300 dark:border-gray-600">
            <img src={`/images/qr/qr-localhost-3000.svg`} alt="" width="250px"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePrompt;