import React from 'react';
import PropTypes from 'prop-types';

const Copyright = ({ url = '/', name = 'Joan Roca Alcarraz' }) => {
  const currentYear = new Date().getFullYear();

  return (
    <span>{currentYear} © <a href={url} className="hover:text-blue-500">{name}</a></span>
  );
};

Copyright.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string
};

export default Copyright;