import React, { useState, useEffect, useRef } from 'react';
//import skillsData from '../../data/skills.json';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import { useTranslation } from 'react-i18next';
//import { useLanguage } from '../../components/LanguageSelector/LanguageContext';
import { LuExternalLink } from "react-icons/lu";
import { isDeveloperDebugger } from '../../constants/Config';
import { COLORS } from '../../constants/Constants';
//import { IoSnowOutline } from "react-icons/io5";
import { TbMarqueeOff } from "react-icons/tb"; // Descatalogados en el tiempo
import { IoMagnetSharp } from "react-icons/io5"; // Interesantes y aprendiendo
import { BiHappyBeaming } from "react-icons/bi"; // Usados en los 2 últimos años



const Skills = () => {
  const { t, i18n } = useTranslation();
  const { isDark } = useDarkMode();
  const [hoveredSkill, setHoveredSkill] = useState(null);
  const [filters, setFilters] = useState({
    obsolete: false,
    active: false,
    connected: true,
    disconnected: false
  });
  const [skillsData, setSkillsData] = useState({ skillGroups: [] });
  const [filteredSkills, setFilteredSkills] = useState([]);
  const groupRefs = useRef({});

  // mejora de carga y de control de errores
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Obtén el nivel mínimo de la variable de entorno
  const minSkillLevel = parseInt(process.env.REACT_APP_MIN_SKILL_LEVEL) || 0;

  const getImageSrc = (icon) => {
    if (icon.startsWith('http') || icon.startsWith('https')) {
      return icon;
    } else {
      try {
        return require(`../../assets/skills/${icon}`);
      } catch (err) {
        console.error(`Error loading image: ${icon}`);
        return '';
      }
    }
  };

  useEffect(() => {
    const loadSkillsData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 segundos de timeout

        const data = await import(`../../data/skills-${i18n.language}.json`);
        clearTimeout(timeoutId);
        setSkillsData(data);
      } catch (error) {
        console.error('Error loading skills data:', error);
        if (error.name === 'AbortError') {
          setError('La carga de datos ha excedido el tiempo límite. Por favor, intenta de nuevo.');
        } else {
          try {
            const defaultData = await import('../../data/skills-en.json');
            setSkillsData(defaultData);
          } catch (fallbackError) {
            setError('No se pudieron cargar los datos de habilidades. Por favor, recarga la página.');
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadSkillsData();
  }, [i18n.language]);

  useEffect(() => {
    const newFilteredSkills = skillsData.skillGroups.map(group => ({
      ...group,
      skills: group.skills.filter(skill => 
        skill.level >= minSkillLevel && (
          (filters.obsolete && skill.status === 'Obsolete') ||
          (filters.active && skill.status === 'Actual') ||
          (filters.connected && skill.use === true) ||
          (filters.disconnected && !skill.use)
        )
      )
    })).filter(group => group.skills.length > 0);
    setFilteredSkills(newFilteredSkills);
  }, [filters, minSkillLevel, skillsData]);

  const toggleFilter = (filterName) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName]
    }));
  };

  const scrollToGroup = (groupName) => {
    groupRefs.current[groupName]?.scrollIntoView({ behavior: 'smooth' });
  };

  if (isLoading) {
    return <div>Cargando habilidades...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div name='skills' className={`w-full min-h-screen ${isDark ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <h1 
          //className="text-4xl font-bold text-center mb-8 text-gray-900 dark:text-white"
          className={`text-4xl md:text-5xl font-bold mb-6 font-sans ${COLORS.gradelux}`}
        >
          {t('skillsTitle1')}
        </h1>
        <div>
          {/*<p className={`text-4xl font-bold inline border-b-4 ${isDark ? 'border-blue-400' : 'border-blue-600'}`} >{t('someKey')}Skills</p>*/}
          <p className='py-4'>{t('skillsText1')}</p>
        </div>

        {/* Índice de etiquetas */}
        <div className="mb-8">
          <h3 className="text-xl font-bold mb-2">{t('skillsSubTitle1')}</h3>
          <div className="flex flex-wrap gap-2">
            {skillsData.skillGroups.map((group, index) => (
              <button
                key={index}
                onClick={() => scrollToGroup(group.name)}
                className={`px-3 py-1 rounded ${isDark ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-100 hover:bg-blue-200'} text-sm`}
              >
                {group.name}
              </button>
            ))}
          </div>
        </div>



        {filteredSkills.map((group, groupIndex) => (
          <div key={groupIndex} className='mb-8' ref={el => groupRefs.current[group.name] = el}>
            <h2 className='text-2xl font-bold mb-4'>{group.name}</h2>
            
            <div className='w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 text-center'>
              {group.skills.map((skill, skillIndex) => (
              <div 
                key={skillIndex} 
                className={`relative shadow-md ${isDark ? 'shadow-gray-700 hover:shadow-blue-500' : 'shadow-gray-300 hover:shadow-gray-500'} hover:scale-110 duration-500 p-4 rounded-lg aspect-square`}
                onMouseEnter={() => setHoveredSkill(skill)}
                onMouseLeave={() => setHoveredSkill(null)}
              >
                <img 
                  className='w-20 h-20 mx-auto object-contain' 
                  src={getImageSrc(skill.icon)}
                  alt={`${skill.name} icon`} 
                />
                <p className='mt-4 text-sm'>{skill.name.toUpperCase()}</p>

                  {skill.status === 'Obsolete' && (
                    <span className="absolute top-2 right-2 bg-red-500/50 text-white/50 text-xs px-2 py-1 rounded"><TbMarqueeOff/></span>
                  )}

                  {skill.status === 'Wish' && (
                    <span className="absolute top-2 right-2 bg-orange-500/50 text-white/50 text-xs px-2 py-1 rounded"><IoMagnetSharp/></span>
                  )}

                  {skill.status === 'Last' && (
                    <span className="absolute top-2 right-2 bg-green-500/50 text-white/50 text-xs px-2 py-1 rounded"><BiHappyBeaming/></span>
                  )}

                  {skill.web && (
                    <a 
                      href={skill.web} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className={`absolute bottom-2 right-2 ${isDark ? 'text-gray-600' : 'text-gray-400'} hover:underline hover:text-blue-400 z-10 flex items-center`}
                    >
                      <span className="text-xs">{t('skillsTxtLink')}</span> <LuExternalLink className="ml-1" size={14} />
                    </a>
                  )}

                  {hoveredSkill === skill && skill.description && (
                    <div className={`absolute inset-0 ${isDark ? 'bg-black bg-opacity-95 text-white' : 'bg-white bg-opacity-95 text-gray-800'} flex flex-col items-center justify-center p-4 rounded-lg transition-opacity duration-300`} style={{pointerEvents: 'none'}}>
                      <p className="text-sm font-bold mb-2">{skill.name}</p>
                      <p className="text-sm">{skill.description}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}

        {isDeveloperDebugger && (
              <>
                <h3 className="text-xl font-bold mb-2">{t('skillsSubTitle2')}</h3>
                <div className="flex space-x-4 mb-4">
                  <button onClick={() => toggleFilter('obsolete')} className={`px-4 py-2 rounded ${filters.obsolete ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('skillsTxtObsoletos')}</button>
                  <button onClick={() => toggleFilter('active')} className={`px-4 py-2 rounded ${filters.active ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('skillsTxtActivos')}</button>
                  <button onClick={() => toggleFilter('connected')} className={`px-4 py-2 rounded ${filters.connected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('skillsTxtConectados')}</button>
                  <button onClick={() => toggleFilter('disconnected')} className={`px-4 py-2 rounded ${filters.disconnected ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('skillsTxtDesconectados')}</button>
                </div>
              </>
            )}

      </div>
    </div>
  );
};

export default Skills;