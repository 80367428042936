import React from 'react';
import AboutMe from '../../components/AboutMe/AboutMe';
import Experience from '../../components/AboutMe/Experience';
import Education from '../../components/AboutMe/Education';

const About3 = () => {
  return (
    <div className="bg-white dark:bg-gray-800">
      <div className="container mx-auto max-w-[1042px] py-20">
        <AboutMe />
        <Experience />
        <Education />
      </div>
    </div>
  );
}

export default About3;