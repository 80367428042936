import portfolioItemsEs from '../data/portfolioItems-es.json';
import portfolioItemsEn from '../data/portfolioItems-en.json';
import portfolioItemsFr from '../data/portfolioItems-fr.json';
import portfolioItemsCat from '../data/portfolioItems-cat.json';

const portfolioItems = {
  es: portfolioItemsEs,
  en: portfolioItemsEn,
  fr: portfolioItemsFr,
  cat: portfolioItemsCat,
};

/* // sin cache
export async function fetchPortfolioItems(language) {
  // Simula una llamada a API con un retraso
  await new Promise(resolve => setTimeout(resolve, 500));
  return [...portfolioItems[language] || portfolioItems.en]; // Crea una nueva copia del array
}
*/

/* // con cache con error timeout
const cache = {};

export async function fetchPortfolioItems(language) {
  if (cache[language]) {
    return cache[language];
  }
  
  await new Promise(resolve => setTimeout(resolve, 500));
  const items = [...portfolioItems[language] || portfolioItems.en];
  cache[language] = items;
  return items;
}

*/

const cache = {};

export async function fetchPortfolioItems(language) {
  // Si los datos están en caché, devuélvelos inmediatamente
  if (cache[language]) {
    return cache[language];
  }

  // Función para simular un retraso
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  // Promesa que se rechaza después de un tiempo límite
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Fetch timeout')), 10000) // 5 segundos de timeout
  );

  try {
    // Usa Promise.race para competir entre el fetch y el timeout
    const result = await Promise.race([
      delay(500).then(() => [...portfolioItems[language] || portfolioItems.en]),
      timeout
    ]);

    // Guarda el resultado en caché
    cache[language] = result;
    return result;
  } catch (error) {
    console.error('Error fetching portfolio items:', error);
    // En caso de error, devuelve un array vacío o los datos en inglés como fallback
    //return [...portfolioItems.en];
    return [...portfolioItems.es];
  }
}

