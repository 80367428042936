import React from 'react';
//import { Link } from 'react-router-dom';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import { useTranslation } from 'react-i18next';

function Terms() {
    
    const { isDark } = useDarkMode();
    const { t } = useTranslation();

    return (
        <section id="home" className={`py-20 flex items-center justify-center ${isDark ? 'dark' : ''}`}>
        
            <h1 className="text-4xl md:text-5xl font-bold mb-6 font-sans text-gray-800 dark:text-white">
                {t('terms')}
            </h1>
            <p className="text-xl mb-8 font-sans text-gray-600 dark:text-gray-300">
                {t('termsText1')}
            </p>

        </section>
    );
}

export default Terms;