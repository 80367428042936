// hoocks/useScrollVisibility.jsx
// Este hook determina si el usuario ha desplazado la página más allá de un cierto umbral. Es comúnmente utilizado para mostrar u ocultar elementos como un botón "Volver arriba" cuando el usuario ha desplazado la página.Este hook determina si el usuario ha desplazado la página más allá de un cierto umbral. Es comúnmente utilizado para mostrar u ocultar elementos como un botón "Volver arriba" cuando el usuario ha desplazado la página.
import { useState, useEffect } from 'react';

const useScrollVisibility = (threshold = 300) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [threshold]);

  return isVisible;
};

export default useScrollVisibility;