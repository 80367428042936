//import { Link } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
import ComputerVideoPlayer from '../../components/ComputerVideoPlayer/ComputerVideoPlayer';
import macosBackground from '../../assets/ui/apple-macbookpro.png';

function Prova2() {
    const { isDark } = useDarkMode();

    return (
        <section id="home" className={`py-20 ${isDark ? 'dark' : ''}`}>
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6 font-sans text-gray-800 dark:text-white">
                        Prova 2 titol
                    </h1>
                    <p className="text-xl mb-8 font-sans text-gray-600 dark:text-gray-300">
                        Prova 2 text
                    </p>
                </div>
                
                <ComputerVideoPlayer
                    //videoUrl="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                    videoUrl="https://localhost:3000/videos/vid-hevidenz-1.mp4"
                    //videoUrl="https://www.xerintel.es/vid/video%202.mp4"
                    //videoUrl="https://private-user-images.githubusercontent.com/105225430/362613683-50a806ec-967d-4d15-bffb-50b43b4981c7.mp4?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3MjgyMzI3MTksIm5iZiI6MTcyODIzMjQxOSwicGF0aCI6Ii8xMDUyMjU0MzAvMzYyNjEzNjgzLTUwYTgwNmVjLTk2N2QtNGQxNS1iZmZiLTUwYjQzYjQ5ODFjNy5tcDQ_WC1BbXotQWxnb3JpdGhtPUFXUzQtSE1BQy1TSEEyNTYmWC1BbXotQ3JlZGVudGlhbD1BS0lBVkNPRFlMU0E1M1BRSzRaQSUyRjIwMjQxMDA2JTJGdXMtZWFzdC0xJTJGczMlMkZhd3M0X3JlcXVlc3QmWC1BbXotRGF0ZT0yMDI0MTAwNlQxNjMzMzlaJlgtQW16LUV4cGlyZXM9MzAwJlgtQW16LVNpZ25hdHVyZT0zODM2OTcxZjcyNzYzZjA4NzYyZWFlMzVjOGYxYzhmNzk3YmNkZWIxMTVhOWE1MmQwM2M0MjExM2Q4OTIxMmZkJlgtQW16LVNpZ25lZEhlYWRlcnM9aG9zdCJ9.t_gjteW4Oon0LMeyjjt_eIOJSr9JwldrwsTjK9Xwbcw"
                    
                    isLaptop={true}
                    backgroundImage={macosBackground}
                />
            </div>
        </section>
    );
}

export default Prova2;

