import React from 'react';
import { Code, Users, Lightbulb } from 'lucide-react';
import { FaCubes } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { useDarkMode } from '../DarkMode/DarkModeContext';

const AboutMe = () => {
  const { t } = useTranslation();
  const { isDark } = useDarkMode();

  return (
    <section id="about-me" className={`m-3 mb-20 ${isDark ? 'text-gray-200' : 'text-gray-800'}`}>
      <h2 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl mb-6 text-left">
        <span className="bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">{t('about.title')}</span>
      </h2>
      <p className={`text-lg mb-12 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
        <span className="vitaminatedText">{t('about.description')}</span>
      </p>
      <div className="grid md:grid-cols-2 gap-8">
        <SkillCard
          icon={Code}
          title={t('about.point1.title')}
          description={t('about.point1.description')}
          isDark={isDark}
        />
        <SkillCard
          icon={Users}
          title={t('about.point2.title')}
          description={t('about.point2.description')}
          isDark={isDark}
        />
        <SkillCard
          icon={Lightbulb}
          title={t('about.point3.title')}
          description={t('about.point3.description')}
          isDark={isDark}
        />
        <SkillCard
          icon={FaCubes}
          title={t('about.point4.title')}
          description={t('about.point4.description')}
          isDark={isDark}
        />
      </div>
    </section>
  );
}

const SkillCard = ({ icon: Icon, title, description, isDark }) => (
  <div className={`flex items-start space-x-4 ${isDark ? 'text-gray-200' : 'text-gray-800'}`}>
    <div className={`flex-shrink-0 -mt-0.5 ${isDark ? 'text-gray-200' : 'text-gray-700'}`} aria-hidden="true">
      <Icon className="w-8 h-8" />
    </div>
    <div>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className={isDark ? 'text-gray-300' : 'text-gray-600'}>
        <span className="vitaminatedText">{description}</span>
      </p>
    </div>
  </div>
);

export default AboutMe;