// components/SkeletonLoader/SkeletonLoader.jsx
import React from 'react';

const SkeletonLoader = () => (
  <section className="bg-gray-100 dark:bg-gray-800 py-12">
    <div className="container mx-auto px-4">
      {/* Título */}
      <div className="h-10 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mx-auto mb-8"></div>
      
      {/* Filtros y buscador */}
      <div className="mb-4 flex justify-between items-center">
        <div className="w-1/3 h-10 bg-gray-300 dark:bg-gray-600 rounded"></div>
        <div className="w-1/3 h-10 bg-gray-300 dark:bg-gray-600 rounded"></div>
      </div>
      
      {/* Grid de items */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {[...Array(6)].map((_, index) => (
          <div key={index} className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden">
            {/* Imagen */}
            <div className="h-48 bg-gray-300 dark:bg-gray-600"></div>
            {/* Contenido */}
            <div className="p-4">
              <div className="h-6 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-full mb-4"></div>
              <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-1/2 mb-2"></div>
              <div className="flex flex-wrap gap-2">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="h-6 w-16 bg-gray-300 dark:bg-gray-600 rounded-full"></div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default SkeletonLoader;