import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Política de Privacidad</h1>
      
      <h2>Introducción</h2>
      <p>JRAdev se compromete a proteger la privacidad de sus usuarios. Esta política de privacidad describe cómo utilizamos y protegemos la información del usuario cuando utiliza nuestro sitio web.</p>
      
      <h2>Procesamiento de la información</h2>
      <p>No recopilamos ni utilizamos ninguna información personal de nuestros usuarios. Todos los datos e información serán procesados por la inteligencia artificial en el servidor en la nube y enviados al usuario.</p>
      
      <h2>Contenido generado por el usuario</h2>
      <p>Procesamos contenido generado por el usuario, como imágenes o texto, para proporcionar nuestros servicios. Sin embargo, no almacenamos ni retenemos ningún contenido generado por el usuario después de que ha sido procesado y enviado al usuario.</p>
      
      {/* Añade el resto de las secciones aquí */}
    </div>
  );
};

export default PrivacyPolicy;