import React from 'react';
//import { Link } from 'react-router-dom';
import { useDarkMode } from '../../components/DarkMode/DarkModeContext';
//import { useTranslation } from 'react-i18next';
import TestimonialSlider from '../../components/TestimonialSlider/TestimonialSlider';
import '../../components/TestimonialSlider/TestimonialSlider.css';

function Prova1() {
    
    const { isDark } = useDarkMode();
    //const { t } = useTranslation();

    return (
        <section id="home" className={`py-20 flex items-center justify-center ${isDark ? 'dark' : ''}`}>
        
            <h1 className="text-4xl md:text-5xl font-bold mb-6 font-sans text-gray-800 dark:text-white">
                Prova 1 titol
            </h1>
            <p className="text-xl mb-8 font-sans text-gray-600 dark:text-gray-300">
                Prova 1 text
                <TestimonialSlider/>
            </p>

        </section>
    );
}

export default Prova1;