// LanguageSelector.jsx
// components/common/LanguageSelector.jsx
// LanguageSelector.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../LanguageSelector/LanguageContext';

function LanguageSelector({ 
  tipo = 'default', // TWdropdown, default, dropdown, flags, text, icon
  customClass = '', 
  languages = ['es', 'en', 'fr', 'cat'],
  icon = null, // Puede ser un componente de icono o una URL de imagen
  closeOnClickOutside = true,
  autoCloseTime = 0 // 0 significa que no se cerrará automáticamente , o {3000} = 3 segundos
}) {
  const { i18n } = useTranslation();
  const { changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const languageNames = {
    es: 'ESP',
    en: 'ENG',
    fr: 'FRA',
    cat: 'CAT'
  };

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };


  useEffect(() => {
    if (closeOnClickOutside) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [closeOnClickOutside]);

  useEffect(() => {
    if (isOpen && autoCloseTime > 0) {
      timeoutRef.current = setTimeout(() => {
        setIsOpen(false);
      }, autoCloseTime);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpen, autoCloseTime]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const renderIcon = () => {
    if (!icon) return null;
    if (typeof icon === 'string') {
      return <img src={icon} alt="Language Icon" className="language-icon" />;
    }
    return icon;
  };

  const renderTailwindDropdownOldStyle = () => (
    <div className={`relative ${customClass}`}>
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className="flex items-center space-x-2 focus:outline-none"
      >
        {renderIcon()}
        <img 
          src={`/images/flags/ban-${i18n.language}.webp`} 
          alt={languageNames[i18n.language]} 
          width="25px"
        />
      </button>
      {isOpen && (
        <div className="absolute top-full right-0 mt-2 w-48 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md shadow-lg z-50">
          {languages.map((lng) => (
            <button 
              key={lng} 
              onClick={() => handleLanguageChange(lng)}
              className={`flex items-center space-x-2 w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 ${i18n.language === lng ? 'bg-gray-200 dark:bg-gray-600' : ''}`}
            >
              <img src={`/images/flags/ban-${lng}.png`} alt={languageNames[lng]} width="25px"/>
              <span>{languageNames[lng]}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const renderTailwindDropdownStyle = () => (
    <div className={`relative ${customClass}`} ref={dropdownRef}>
      <button 
        onClick={toggleDropdown} 
        className="flex items-center space-x-2 focus:outline-none"
      >
        {renderIcon()}
        <img 
          //src={`/images/flags/ban-${i18n.language.slice(0, 2)}.webp`} 
          //src={`/images/flags/ban-${i18n.language}.webp`} 
          src={`/images/flags/ban-${i18n.language.slice(0, 2) === 'ca' ? 'cat' : i18n.language.slice(0, 2)}.webp`}
          alt={languageNames[i18n.language]} 
          width="25px"
        />
      </button>
      {isOpen && (
        <div className="absolute top-full right-0 mt-2 w-48 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md shadow-lg z-50">
          {languages.map((lng) => (
            <button 
              key={lng} 
              onClick={() => handleLanguageChange(lng)}
              className={`flex items-center space-x-2 w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 ${i18n.language === lng ? 'bg-gray-200 dark:bg-gray-600' : ''}`}
            >
              <img src={`/images/flags/ban-${lng}.webp`} alt={languageNames[lng]} width="25px"/>
              <span>{languageNames[lng]}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const renderDefaultStyle = () => (
    <div className={`language-selector ${customClass}`}>
      {renderIcon()}
      {languages.map((lng) => (
        <button 
          key={lng} 
          onClick={() => handleLanguageChange(lng)}
          className={`language-button ${i18n.language === lng ? 'active' : ''}`}
        >
          <img src={`/images/flags/ban-${lng}.png`} alt={languageNames[lng]} width="25px"/>
          {languageNames[lng]}
        </button>
      ))}
    </div>
  );

  const renderDropdownStyle = () => (
    <div className={`language-selector-dropdown ${customClass}`}>
      {renderIcon()}
      <button onClick={() => setIsOpen(!isOpen)} className="current-language">
        <img src={`/images/flags/ban-${i18n.language}.png`} alt={languageNames[i18n.language]} width="25px"/>
      </button>
      {isOpen && (
        <div className="language-dropdown">
          {languages.map((lng) => (
            <button 
              key={lng} 
              onClick={() => handleLanguageChange(lng)}
              className={`language-option ${i18n.language === lng ? 'active' : ''}`}
            >
              <img src={`/images/flags/ban-${lng}.png`} alt={languageNames[lng]} width="25px"/>
              {languageNames[lng]}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const renderFlagsStyle = () => (
    <div className={`language-selector-flags ${customClass}`}>
      {renderIcon()}
      {languages.map((lng) => (
        <button 
          key={lng} 
          onClick={() => handleLanguageChange(lng)}
          className={`language-flag ${i18n.language === lng ? 'active' : ''}`}
          style={{ opacity: i18n.language === lng ? 1 : 0.4 }}
          onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
          onMouseLeave={(e) => e.currentTarget.style.opacity = i18n.language === lng ? 1 : 0.4}
        >
          <img src={`/images/flags/ban-${lng}.png`} alt={languageNames[lng]} width="25px"/>
        </button>
      ))}
    </div>
  );

  const renderTextStyle = () => (
    <div className={`language-selector-text ${customClass}`}>
      {renderIcon()}
      {languages.map((lng, index) => (
        <React.Fragment key={lng}>
          <button 
            onClick={() => handleLanguageChange(lng)}
            className={`language-text ${i18n.language === lng ? 'active' : ''}`}
            style={{ textDecoration: i18n.language === lng ? 'underline' : 'none' }}
          >
            {languageNames[lng]}
          </button>
          {index < languages.length - 1 && " | "}
        </React.Fragment>
      ))}
    </div>
  );

  const renderIconStyle = () => (
    <div className={`language-selector-icon ${customClass}`}>
      {renderIcon()}
      <button onClick={() => setIsOpen(!isOpen)} className="current-language">
        {languageNames[i18n.language]}
      </button>
      {isOpen && (
        <div className="language-dropdown">
          {languages.map((lng) => (
            <button 
              key={lng} 
              onClick={() => handleLanguageChange(lng)}
              className={`language-option ${i18n.language === lng ? 'active' : ''}`}
            >
              {languageNames[lng]}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  switch (tipo) {
    case 'TWdropdown':
     return renderTailwindDropdownStyle();
     case 'TWdropdownOld':
      return renderTailwindDropdownOldStyle();
    case 'dropdown':
      return renderDropdownStyle();
    case 'flags':
      return renderFlagsStyle();
    case 'text':
      return renderTextStyle();
    case 'icon':
      return renderIconStyle();
    default:
      return renderDefaultStyle();
  }
}

export default LanguageSelector;