// Footer.jsx
import React, { useRef } from 'react';
//import { IoLogoLinkedin, IoLogoGithub, IoLogoInstagram, IoIosArrowUp } from "react-icons/io5";
import { IoLogoLinkedin, IoLogoGithub, IoLogoInstagram } from "react-icons/io5";
import ScrollToTopButton from '../common/ScrollToTopButton';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import { useLanguage } from '../LanguageSelector/LanguageContext';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
//import { FaArrowUp } from 'react-icons/fa'; 
import { FaChevronUp } from "react-icons/fa6";
import Version from '../Version/Version';
import Copyright from '../Copyright/Copyright';


function Footer() {

  const footerRef = useRef(null);
  const { t } = useTranslation();
  //const { currentLanguage, changeLanguage } = useLanguage();
  //const { changeLanguage } = useLanguage();

  const location = useLocation();
  const isHomeOrAbout = location.pathname === '/' || location.pathname === '/about'|| location.pathname === '/home';

  return (
    <footer ref={footerRef}  className="bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300">
     {!isHomeOrAbout && (
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-wrap -mx-4">
          
          <div className="w-full md:w-5/12 px-4 mb-8 md:mb-0">
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 mr-4">
                <a href="/about/">
                  <img className="rounded-full" src="/images/profile.png" alt="Joan Roca Alcarraz" />
                </a>
              </div>
              <h3 className="text-xl font-semibold">
                <a href="/about/" className="hover:text-blue-500">{t('footerTitle1')}</a>
              </h3>
            </div>
            <p className="mb-4">
            {t('footerText1')}
            </p>
            <p className="mb-4">{t('footerText2')}</p>
            <div className="flex space-x-4">
              <a href="https://www.linkedin.com/in/joan-roca-69aabb81/" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-blue-500"><IoLogoLinkedin /></a>
              <a href="https://github.com/noolloon" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-blue-500"><IoLogoGithub /></a>
              <a href="https://github.com/joanqa3" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-blue-500"><IoLogoGithub /></a>
              <a href="https://www.instagram.com/noolloon5" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-blue-500"><IoLogoInstagram /></a>
            </div>
          </div>

          <div className="w-full md:w-6/12 md:ml-auto px-4">
            <h3 className="text-xl font-semibold mb-4">{t('footerGallery')}</h3>
            <div className="grid grid-cols-3 gap-4">
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <div key={num} className="aspect-w-1 aspect-h-1 hover:scale-110 duration-500">
                  <a href="https://www.instagram.com/noolloon5" target="_blank" rel="noopener noreferrer">
                    <img src={`/images/gallery/ig-soft-${num}.jpg`} alt={`Gallery ${num}`} className="object-cover w-full h-full rounded-md" />
                  </a>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    )}
      
      <div className="border-t border-gray-200 dark:border-gray-700">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="text-sm">
              <Copyright url="/" name="Joan Roca Alcarraz" />
            </div>
            <ScrollToTopButton
              icon={<FaChevronUp />}
              //bottomOffset={250}
              footerRef={footerRef}
              position="bottom-right" // bottom-left, bottom-right, top-left, top-right
            />
            <Version className="text-sm" />
            <div>
              <LanguageSelector 
                tipo='text' // default, dropdown, flags, text
                showFlags={false}
                showText={true}
                customClass="footer-language-selector"
                languages={['es', 'en', 'fr', 'cat']}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;