import React from 'react'
import { User, Mail, Phone, Globe, Award, Code, Briefcase, GraduationCap, FileText } from 'lucide-react'

const InfoItem = ({ icon: Icon, title, content }) => (
  <div className="flex items-start mb-4">
    <Icon className="w-6 h-6 mr-2 text-blue-600 flex-shrink-0" />
    <div>
      <h3 className="font-semibold">{title}</h3>
      <div className="text-gray-600">{content}</div>
    </div>
  </div>
)

function About1() {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-4">Joan Roca Alcarraz</h1>
          <p className="text-gray-600 mb-6">Desarrollador Software y Gestor de Proyectos</p>
          
          <InfoItem icon={User} title="Fecha de Nacimiento" content="23/02/1977" />
          <InfoItem icon={Mail} title="Email" content="noolloon@gmail.com" />
          <InfoItem icon={Phone} title="Teléfono" content="+34 679 29 49 31" />
          <InfoItem icon={Globe} title="Idiomas" content="Catalán (Nativo), Castellano (Nativo), Inglés (Profesional, C1.1 UOC), Francés (Medio)" />
          
          <InfoItem 
            icon={Award} 
            title="Certificaciones" 
            content="Certificado Profesional Desarrollador de Meta en iOS (2023-2024)" 
          />
          
          <InfoItem 
            icon={Code} 
            title="Habilidades Técnicas" 
            content="Swift, ReactNative, Flutter, MySQL, PostgreSQL, CoreData, PHP, Woocommerce, Agile, Scrum Master, JIRA, PowerBI" 
          />
          
          <InfoItem 
            icon={Briefcase} 
            title="Competencias" 
            content="Desarrollador Software (iOS/Android), Gestión de Proyectos" 
          />
          
          <InfoItem 
            icon={GraduationCap} 
            title="Educación" 
            content={
              <div>
                <div>Técnico en 3D e Imágenes de Síntesis, IDEP (2003-2005)</div>
                <div>Diseño Gráfico y Publicitario, La Llotja (1994-1999)</div>
              </div>
            } 
          />
          
          <InfoItem 
            icon={FileText} 
            title="Formación Complementaria" 
            content="Cursos en Flutter, Dart, Swift, SwiftUI, ReactNative, Android, iOS, PWA, React, Angular, Capacitor, IONIC, C++, Java, PHP, Node, Firebase" 
          />
          
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Experiencia Laboral</h2>
            {[
              { company: "Hevidenz.com", period: "Feb 2023 - Jun 2024", role: "Desarrollo y gestión de tienda online en PHP y Woocommerce, gestión de Amazon Seller en 10 países." },
              { company: "Ketachi.com", period: "Feb 2022 - Ene 2023", role: "Gestión y optimización de Amazon Seller." },
              { company: "Relappro.com - Relapcode.com", period: "Ene 2021 - Ene 2022", role: "Consultor IT y Agile Coach, coordinación de proyectos móviles." },
              { company: "OSAM Ayuntamiento de Barcelona", period: "Nov 2017 - Dic 2020", role: "Jefe de Proyectos Técnicos, coordinación de proyectos móviles para Smou, Bicing, entre otros." },
              { company: "Netsonic.tv (Mediaset)", period: "Nov 2013 - Sep 2015", role: "Gestión técnica de redes de publicidad digital." },
              { company: "MUUBY", period: "2010-2011", role: "Director Técnico." },
              { company: "Alterna Project Marketing (Eurostar Mediagroup/ZonaAPP)", period: "2011-Oct 2013", role: "Director Creativo." },
              { company: "Adsmedia Mobile Advertising", period: "2008-2010", role: "Jefe de Proyectos Técnicos." }
            ].map((job, index) => (
              <div key={index} className="mb-4">
                <h3 className="font-semibold">{job.company}</h3>
                <div className="text-sm text-gray-600">{job.period}</div>
                <div className="text-gray-700">{job.role}</div>
              </div>
            ))}
          </div>
          
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">Responsabilidades Clave</h2>
            <ul className="list-disc list-inside text-gray-700">
              <li>Desarrollo de aplicaciones móviles y multiplataforma</li>
              <li>Gestión de proyectos Agile</li>
              <li>Optimización de campañas publicitarias</li>
              <li>Liderazgo de equipos creativos y técnicos</li>
            </ul>
          </div>
          
          <InfoItem 
            icon={FileText} 
            title="Metodologías" 
            content="Agile, Scrum, JIRA" 
          />
          
          <InfoItem 
            icon={Globe} 
            title="Plataformas" 
            content="Amazon Seller, AWS, Linux, Salesforce, Google Cloud Platform" 
          />
        </div>
      </div>
    </div>
  )
}

export default About1