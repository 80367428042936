import React from 'react'
import About1 from './About1'
import About2 from './About2'

function Comparar() {
  return (
    <div>
      <h1 className="text-3xl font-bold text-center my-8">Comparación de Componentes About</h1>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">About1 (Versión Original)</h2>
          <About1 />
        </div>
        <div className="w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">About2 (Nueva Versión)</h2>
          <About2 />
        </div>
      </div>
    </div>
  )
}

export default Comparar