import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const testimonials = [
  {
    id: 1,
    name: 'DEGIRO',
    text: 'JRAdev ha sido de gran valor para DEGIRO, ya que nos permite gestionar todos nuestros programas de afiliados en una sola plataforma y escalarlos fácilmente. Además, su atención al cliente es excelente.',
    image: '/images/empresas/degiro-1.png',
    logo: '/images/empresas/degiro-logo.png'
  },
  {
    id: 2,
    name: 'lightspeed',
    text: 'JRAdev ha simplificado el proceso de configurar un programa de afiliados para lightspeed, permitiendo a nuestros clientes crear valor sostenible al construir su propia red de promotores.',
    image: '/images/empresas/lightspeed-1.png',
    logo: '/images/empresas/lightspeed-logo.png'
  },
  {
    id: 3,
    name: 'OPTMYZR',
    text: 'Antes de JRAdev, teníamos dificultades para encontrar un software de afiliados que se adaptara a nuestro programa cerrado por invitación. JRAdev nos proporcionó una solución poderosa y fácil de usar que nos permitió invitar selectivamente a afiliados de alta calidad y mantener el control.',
    image: '/images/empresas/optmyzr-1.png',
    logo: '/images/empresas/optmyzr-logo.png'
  },
  // ... más testimonios
];

const TestimonialSlider = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);

  const itemsPerPage = 3;
  const totalPages = Math.ceil(testimonials.length / itemsPerPage);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDragging) {
        setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
      }
    }, 5000); // Cambia cada 5 segundos

    return () => clearInterval(interval);
  }, [isDragging, totalPages]);

  const handleDragStart = () => setIsDragging(true);
  const handleDragEnd = (event, info) => {
    setIsDragging(false);
    if (Math.abs(info.offset.x) > 100) {
      if (info.offset.x > 0) {
        setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
      } else {
        setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
      }
    }
  };

  const renderTestimonials = () => {
    const start = currentPage * itemsPerPage;
    return testimonials.slice(start, start + itemsPerPage).map((testimonial) => (
      <motion.div
        key={testimonial.id}
        className="testimonial-item"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src={testimonial.image} alt={testimonial.name} className="client-image" />
        <img src={testimonial.logo} alt={`${testimonial.name} logo`} className="company-logo" />
        <p>{testimonial.text}</p>
        <h3>{testimonial.name}</h3>
      </motion.div>
    ));
  };

  return (
    <div className="testimonial-slider">
      <motion.div
        ref={sliderRef}
        className="testimonial-container"
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <AnimatePresence mode="wait">
          {renderTestimonials()}
        </AnimatePresence>
      </motion.div>
      <div className="pagination">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={`pagination-dot ${index === currentPage ? 'active' : ''}`}
            onClick={() => setCurrentPage(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;