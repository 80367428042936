import React from 'react';
import { Code, Users, Lightbulb } from 'lucide-react';

const LogoComponent = ({ src, alt, width, height }) => (
  <img src={src} alt={alt} width={width} height={height} className="rounded-full" />
);

const About2 = () => {
  return (
    <>
      <section id="about-me" className="py-20 bg-white dark:bg-gray-800">
        <div className="container mx-auto max-w-[1042px]">
          <h2 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl mb-6 text-left">
            <span className="bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">Sobre Mí</span>
          </h2>
          <p className="text-lg text-gray-300 mb-12">
            <span className="vitaminatedText">Más de 15 años de experiencia como Desarrollador de Software y Gestor de Proyectos. Trabajando con equipos de producto enfocados en el cliente final, así como en equipos de plataforma.</span>
          </p>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-start space-x-4 text-gray-100">
              <div className="flex-shrink-0 -mt-0.5 text-gray-100" aria-hidden="true">
                <Code className="w-8 h-8" />
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Desarrollador Multiplataforma</h3>
                <p className="text-gray-300">
                  <span className="vitaminatedText">Ingeniero de Software con experiencia en desarrollo de aplicaciones móviles y web, impulsando la eficiencia y escalabilidad en sistemas complejos.</span>
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4 text-gray-100">
              <div className="flex-shrink-0 -mt-0.5 text-gray-100" aria-hidden="true">
                <Users className="w-8 h-8" />
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Colaborador Multifuncional</h3>
                <p className="text-gray-300">
                  <span className="vitaminatedText">Con experiencia en equipos de frontend, backend y gestión de proyectos, disfruto colaborando con equipos multifuncionales para dar vida a ideas innovadoras.</span>
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4 text-gray-100">
              <div className="flex-shrink-0 -mt-0.5 text-gray-100" aria-hidden="true">
                <Lightbulb className="w-8 h-8" />
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Soluciones Orientadas al Valor</h3>
                <p className="text-gray-300">
                  <span className="vitaminatedText">Me apasiona trabajar en equipos de producto y plataforma, creando soluciones digitales que aportan valor e impactan a los usuarios finales.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="experience" className="container py-8 mx-auto">
        <div className="mx-auto max-w-[980px] flex flex-col items-start gap-2 space-y-6">
          <h2 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl flex items-center mb-8 w-full justify-between">
            <span className="bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">Experiencia</span>
          </h2>
          <div className="space-y-12">
            <div className="relative flex">
              <div className="absolute top-[3.3rem] left-6 h-[calc(100%)] w-px bg-gray-700" aria-hidden="true"></div>
              <div className="flex-shrink-0 w-12 h-12 mt-1">
                <div className="w-12 h-12 rounded-full border-4 border-gray-800 bg-gray-800 z-10 overflow-hidden">
                  <LogoComponent 
                    src="/images/bi/hevidenz.webp"
                    alt="Logo de Hevidenz"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="ml-6 flex-grow">
                <div className="flex flex-col sm:flex-row justify-between items-start">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <h3 className="text-xl font-bold text-gray-100">
                        <a target="_blank" rel="noopener noreferrer" className="inline-flex items-center group hover:underline" aria-label="Hevidenz (opens in a new tab)" href="https://hevidenz.com">Hevidenz
                          <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-external-link ml-1 h-4 w-4 transition-opacity opacity-0 group-hover:opacity-100" aria-hidden="true">
                            <path d="M15 3h6v6"></path>
                            <path d="M10 14 21 3"></path>
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                          </svg>
                        </a>
                      </h3>
                    </div>
                    <p className="text-lg font-semibold text-gray-100 mt-1">Desarrollador y Gestor de Proyectos</p>
                  </div>
                  <div className="text-gray-400 w-full sm:w-auto sm:text-right mt-2 sm:mt-0">
                    <time dateTime="2023-02">Feb 2023 - Jun 2024</time>
                  </div>
                </div>
                <ul className="list-disc list-inside mt-4 mb-6 space-y-2" aria-label="Responsabilidades">
                  <li className="leading-relaxed text-gray-300">
                    <span className="vitaminatedText">Desarrollo y gestión de tienda online en PHP y Woocommerce, implementando sistemas de alto rendimiento que manejan 
                      <mark style={{ color: '#5BC0BE' }} className="font-bold bg-transparent" aria-label="Emphasized: millones de productos">millones de productos</mark>.
                    </span>
                  </li>
                  <li className="leading-relaxed text-gray-300">
                    <span className="vitaminatedText">Gestión de Amazon Seller en 
                      <mark style={{ color: '#5BC0BE' }} className="font-bold bg-transparent" aria-label="Emphasized: 10 países">10 países</mark>, optimizando las operaciones y aumentando las ventas.
                    </span>
                  </li>
                  <li className="leading-relaxed text-gray-300">
                    <span className="vitaminatedText">Implementación de estrategias de 
                      <mark style={{ color: '#5BC0BE' }} className="font-bold bg-transparent" aria-label="Emphasized: SEO y marketing digital">SEO y marketing digital</mark> para mejorar la visibilidad y las conversiones de la tienda online.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* Puedes agregar más experiencias laborales siguiendo el mismo patrón */}
          </div>
        </div>
      </section>

      <section id="education" className="py-8 lg:py-18">
        <div className="container mx-auto max-w-[1042px]">
          <h2 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl flex items-center mb-8 w-full justify-between">
            <span className="bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent">
              Educación
            </span>
          </h2>
          <div className="space-y-8">
            <article className="flex flex-row items-start gap-4 sm:gap-8">
              <div className="w-16 h-16 relative flex-shrink-0">
                <img
                  alt="IDEP logo"
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  className="rounded-full object-contain"
                  style={{ position: 'absolute', height: '100%', width: '100%', left: 0, top: 0, right: 0, bottom: 0, color: 'transparent' }}
                  sizes="(max-width: 640px) 64px, 64px"
                  src="/images/idep-logo.webp"
                />
              </div>
              <div className="flex flex-col flex-grow">
                <header className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2">
                  <h3>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xl font-bold hover:underline inline-flex items-center group text-gray-100"
                      aria-label="IDEP (opens in a new tab)"
                      href="https://www.idep.es"
                    >
                      IDEP
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-external-link ml-1 h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity"
                        aria-hidden="true"
                      >
                        <path d="M15 3h6v6"></path>
                        <path d="M10 14 21 3"></path>
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      </svg>
                    </a>
                  </h3>
                  <time
                    className="text-gray-400 w-full sm:w-auto sm:text-right mt-1 sm:mt-0"
                    dateTime="2003-2005"
                  >
                    2003 - 2005
                  </time>
                </header>
                <p className="text-base sm:text-lg text-gray-300">
                  Técnico en 3D e Imágenes de Síntesis
                </p>
              </div>
            </article>
            {/* Puedes agregar más educación siguiendo el mismo patrón */}
          </div>
        </div>
      </section>
    </>
  );
}

export default About2;