// PortfolioItem.jsx

import React from 'react';
//import React, { useState, useEffect } from 'react';


function PortfolioItem({ title, description, image, date, company, categories, onClick, ImageComponent }) {
/*
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 500);
    return () => clearTimeout(timer);
  }, []); // Array de dependencias vacío = solo una vez se ejecuta 

    <div 
      className={`bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden hover:scale-105 duration-300 cursor-pointer group transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      onClick={onClick}
    >
  */
  return (
    <div 
      className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden hover:scale-105 duration-300 cursor-pointer group"
      onClick={onClick}
    >
      <div 
        className="relative"
      >
        {ImageComponent ? (
          <ImageComponent 
            src={image} 
            alt={title} 
            //className="w-full h-48 object-cover transition-all duration-300
            className="w-full h-full object-cover transition-all duration-300
              dark:brightness-75 dark:contrast-125 dark:saturate-75
              group-hover:brightness-100 group-hover:contrast-100 group-hover:saturate-100"
          />
        ) : (
          <img 
            src={image} 
            alt={title} 
            className="w-full h-full object-cover transition-all duration-300
                       dark:brightness-75 dark:contrast-125 dark:saturate-75
                       group-hover:brightness-100 group-hover:contrast-100 group-hover:saturate-100"
          />
        )}
        {/* bg-[#374151] */}
        <div className="absolute inset-0 gray-custom opacity-10 transition-opacity duration-300 group-hover:opacity-0 dark:opacity-40 bg-white dark:bg-gray-700"></div>
        <span className="absolute top-2 right-2 bg-white dark:bg-gray-800 text-sm font-semibold px-2 py-1 rounded-full text-gray-800 dark:text-gray-200">
          {date}
        </span>
      </div>
      <div className="p-4">
        <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-300">{title}</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">{description}</p>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">Company: {company}</p>
        <div className="flex flex-wrap gap-2">
          {categories.map(category => (
            <span key={category} className="bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100 text-xs font-semibold px-2.5 py-0.5 rounded-full transition-colors duration-300 group-hover:bg-blue-200 dark:group-hover:bg-blue-700">
              {category}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(PortfolioItem);